export const routesConfig = {
  affiliateLogin: '/affiliatelogin',
  nonServiceable: '/nonserviceable',
  order: '/order',
  pageNotFound: '/404',
  reach: '/reach',
  serviceability: '/serviceability',
  summary: '/summary',
  thanks: '/thanks',
  vas: '/vas',
  vasOrder: '/vasOrder',
  mobileActivation: '/mobileActivation',
};

export const ATT_VENDORS = ['att', 'at&t', 'frontier'];

export const confirmPagePhone = '888-327-8454';

export const vasLearnMoreLinks = {
  protect: 'cyber-safety/',
  guardian: 'parental-control/',
  backup: 'data-backup/',
  easytech: 'remote-it-support/',
};

export const SUGGESTED_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'msn.com',
  'comcast.net',
  'live.com',
  'rediffmail.com',
  'ymail.com',
  'outlook.com',
  'cox.net',
  'sbcglobal.net',
  'verizon.net',
  'rocketmail.com',
  'att.net',
  'facebook.com',
  'bellsouth.net',
  'charter.net',
  'shaw.ca',
  'sky.com',
];

export const OrderStatus = {
  acctEmail: 'acct-email:in-progress',
  addedToCart: 'added-to-cart',
  basicInfo: 'basic-info:in-progress',
  ccInfo: 'cc-info:in-progress',
  newSession: 'session-started',
  orderError: 'order-error/abandoned',
  orderSubmit: 'order-submit:complete',
  secQaInfo: 'sec-qa-info:in-progress',
  serviceCheck: 'serviceable',
  tcDeclined: 'tcDeclined',
  unserviceable: 'unserviceable',
  vasAdded: 'vas-added',
  vasDeclined: 'vas-declined',
  walmartStore: 'walmart-store',
};

export const APIKeys = {
  SmartStreets: '674755572382025',
};

// export const billingPeriod = (isPreferredLangSpanish) => {
//   daily: ' / day',
//   monthly:  isPreferredLangSpanish ? ' / mes' : ' / mo',
//   yearly: ' / yr',
// }

export const ENGLISH_TO_SPANSIH_TRANSLATION = {
  english: {
    name: 'Name',
    header: 'Review Order',
    activationCharges: 'Activation Charge',
    activationInformation: 'Activation Information',
    dslShippingCharge: 'DSL Shipping Charge',
    subHeader: '(Review Details with Customer)',
    serviceOrShipping: 'Service / Shipping',
    service: 'Service',
    services: 'Services',
    simCharge: 'SIM Charge',
    mobileNotCharged: 'Mobile not charged until Activation.',
    installation: 'Installation',
    installationCost: 'Installation Cost',
    activationFee: 'Activation Fee',
    installationInformation: 'Installation Information',
    installationFee: 'installation fee',
    monthlyCharges: 'Monthly Charges',
    monthlyCharge: 'Monthly Charge',
    monthlyTotal: 'Monthly Total',
    cardEndingIn: 'Card ending in',
    modemLease: 'Modem Lease',
    monthly: 'Monthly',
    todaysTotal: 'Today\'s Total',
    offerTerms: 'Offer Terms',
    readVerbatim: '(Read Verbatim)',
    earthLinkGeneral: 'General',
    li1:
    'You will be charged on a monthly basis for Service, billed in advance to your designated Method of Payment.',
    li2:
    'At the end of your initial Term, if applicable, your Service will automatically renew at the then-applicable rates.',
    li3:
    'You may cancel your Service at any time prior to the next billing cycle to avoid incurring additional charges.',
    li4:
    'EarthLink does not grant refunds or credits for any prior use including partial use during the last month prior to cancellation.',
    li5:
    'You can learn more about our Terms of Service, Acceptable Use and Privacy Policy at EarthLink.net/TCS, EarthLink.net/TCS/Mobile, and/or Earthlink.net/TCS/Promotions/ as applicable.',
    endSession: 'End Session',
    submitOrder: 'SUBMIT ORDER',
    termsConditionsLabel1: 'Offer Terms and Conditions',
    termsConditionsLabel2: 'If Customer Agrees to the Offer Terms',
    termsConditionsBoldText1: '(Read Verbatim and seek Verbal Agreement)',
    termsConditionsBoldText2: 'May I have your permission to place the Order?',
    earthLinkInternet: 'Internet',
    plusApplicableTaxes: 'Plus Applicable Taxes',
    promoCode: 'Promo Code',
    apply: 'Apply',
    clear: 'Clear',
    placeHolder: 'Enter or Choose From List',
    oneTimeCharge: 'One-time Charge',
    oneTimeCharges: 'One-time Charges',
    orderChecklist: 'Order Confirmation Checklist',
    professionalInstallation: 'Professional installation',
    providedBy: 'provided by',
    the: 'The',
    chargedToday: 'charged today',
    installationDate: ' Your installation date will be',
    between: 'between',
    and: 'and ',
    orderConfirmed: 'Order Confirmed',
    thanks: 'Thank you',
    confirmationNumber: 'Confirmation Number',
    installationSucces: 'Your Installation date and time is',
    totalMonthlyCharges: 'Total Monthly Charges',
    orderConfirmationDesc:
      'If you have questions about your order and next steps, please call',
    yourAccountInformation: 'Your Account Information',
    accountEmail: 'Account Email',
    accountNumber: 'Account Number',
    emailAddress: 'Email Address',
    installationLI1: 'You will be',
    installationLI2: 'regarding installation details for your appointment.',
    installationB: 'notified via text',
    installationServiceLI: 'Your service will be',
    installationServiceB: 'installed in',
    today: 'Due Today',
    aboutAWeek: 'about a week',
    days: '5-7 days',
    callBack: 'Callback',
    leadSearch: 'Lead Search',
    welcome: 'Welcome',
    copySessionId: 'Copy Session Id',
    fee: 'fee',
    willChargedToday: 'will be charged today',
    reportAnIssue: 'Report an Issue',
    backToHome: 'BACK TO HOME',
    mobileServiceInfo: 'Mobile Service Information',
    bundleDiscount: 'Bundle Discount Applied',
    firstMonth: 'First Month',
    mobile: 'Mobile',
    MLPTerms:
      '**Applicable for 1st three months, then standard charges will be applicable',
    readTerm: 'Read Terms',
    sendTerm: 'Send Terms',
    verizonTerm: 'If you cancel your service, you will need to send the router back to EarthLink to avoid an Equipment fee.',
    sendButton: 'Send',
    textSMS: 'Text / SMS',
    email: 'Email',
    code: 'Code',
    sendConfirm: 'Confirm',
    emailInvalid: 'Invalid email',
    phoneInvalid: 'Please enter a valid phone number',
    codeIncorrect: 'Terms Confirmation code is incorrect',
    codeSuccess:
      'Customer confirmed receipt and understanding of the EarthLink Terms sent to them.',
    emailVerify: 'Invalid Email or Domain. Please re-enter Email Address',
    emailMessage:
      'Email format is correct, but verify its accuracy with Customer',
    processingHandling:'Processing & Handling Fee',
    serviceAutoActivates: 'Service automatically activates 10 days after Equipment delivery',
  },
  spanish: {
    name: 'Nombre',
    header: 'Revisar la Orden',
    activationCharges: 'Cargo de activación',
    activationInformation: 'Información de activación',
    dslShippingCharge: 'Cargo de envío de DSL',
    subHeader: '(Revisar los detalles con el cliente)',
    serviceOrShipping: 'Servicio / Envío',
    service: 'Servicio',
    services: 'Servicios',
    simCharge: 'Cargo SIM',
    mobileNotCharged:
      'El servicio de telefonía y datos se cobrará después de la activación.',
    installation: 'Instalación',
    installationCost: 'Costo de instalacion',
    activationFee: 'Tarifa de activación',
    installationInformation: 'Información de instalación',
    installationFee: 'tarifa de instalación',
    monthlyCharges: 'Cargos mensuales',
    monthlyCharge: 'Cargo mensual',
    monthlyTotal: 'Total Mensual',
    cardEndingIn: 'Tarjeta que termina en',
    modemLease: 'Renta del módem',
    todaysTotal: 'Total de hoy',
    offerTerms: 'Términos de la oferta',
    readVerbatim: '(Leer palabra por palabra)',
    earthLinkGeneral: 'General',
    li1: 'Se le cobrará mensualmente por el Servicio, facturado por adelantado a su Método de pago designado.',
    li2:
      'Al final de su término de contrato, si aplica, su servicio será automaticamente renovado con las tarifas de ese momento.',
    li3:
      'Puede cancelar su Servicio en cualquier momento antes del próximo ciclo de facturación para evitar incurrir en cargos adicionales.',
    li4:
      'EarthLink no otorga reembolsos ni créditos por ningún uso anterior, incluido el uso parcial durante el último mes anterior a la cancelación.',
    li5:
      'Puede obtener más información sobre nuestros Términos de servicio, Uso aceptable y Política de privacidad en EarthLink.net/TCS, EarthLink.net/TCS/Mobile y/o EarthLink.net/TCS/Promotions, según corresponda.',
    endSession: 'Finalizar sesión',
    submitOrder: 'Enviar la orden',
    termsConditionsLabel1: 'Términos y condiciones de la oferta',
    termsConditionsLabel2: 'Si el cliente acepta los términos de la oferta',
    termsConditionsBoldText1:
      '(Leer palabra por palabra y buscar acuerdo verbal)',
    termsConditionsBoldText2:
      '¿Puedo obtener su permiso para realizar el pedido?',
    earthLinkInternet: 'Internet',
    earthlinkInternetLI:
      'Su servicio de Internet EarthLink requiere una suscripción de [12] meses que incluye una terminación anticipada de hasta $200.',
    plusApplicableTaxes: 'Más impuestos aplicables',
    promoCode: 'Código promocional',
    apply: 'Aplicar',
    clear: 'Borrar',
    placeHolder: 'Ingrese o elija de la lista',
    oneTimeCharge: 'Cargo único',
    oneTimeCharges: 'Cargos únicos',
    orderChecklist: 'Lista de verificación de confirmación de pedido',
    professionalInstallation: 'Instalación profesional',
    providedBy: 'proporcionado por',
    the: 'La',
    chargedToday: 'cargado hoy',
    installationDate: 'Su fecha de instalación será',
    between: 'Entre',
    and: 'y',
    orderConfirmed: 'Orden Confirmada',
    thanks: 'Gracias',
    confirmationNumber: 'Número de confirmación',
    installationSucces: 'Su fecha y hora de instalación es',
    totalMonthlyCharges: 'Cargo Total Mensual',
    orderConfirmationDesc:
      'Si tiene alguna pregunta sobre su pedido o el servicio, llame al',
    yourAccountInformation: 'Información de su cuenta',
    accountEmail: 'Cuenta de email',
    accountNumber: 'Número de cuenta',
    emailAddress: 'Dirección de correo electrónico',
    installationLI1: 'Tú serás',
    installationLI2: 'con respecto a los detalles de instalación para su cita.',
    installationB: 'notificado por mensaje de texto',
    installationServiceLI: 'su servicio será',
    installationServiceB: 'instalado en',
    today: 'Hoy',
    monthly: 'Mensual',
    aboutAWeek: 'Alrededor de una semana',
    days: '5-7 días',
    callBack: 'Llamar de vuelta',
    leadSearch: 'Búsqueda de clientes potenciales',
    welcome: 'Bienvenidos',
    copySessionId: 'Copiar ID de sesión',
    fee: 'tarifa',
    willChargedToday: 'se cobrará hoy',
    reportAnIssue: 'Reportar un problema',
    backToHome: 'BACK TO HOME',
    mobileServiceInfo: 'Información del servicio móvil',
    bundleDiscount: 'Descuento por paquete aplicado',
    firstMonth: 'Primer Mes',
    mobile: 'Móvil',
    MLPTerms:
      '**Aplicable para los primeros tres meses, luego se aplicarán los cargos estándar',
    readTerm: 'Leer términos',
    sendTerm: 'Enviar Términos',
    verizonTerm: 'Si cancela su servicio, deberá devolver el enrutador a EarthLink para evitar una tarifa de enrutador no devuelta.',
    sendButton: 'Enviar',
    textSMS: 'Texto / SMS',
    email: 'Correo electrónico',
    code: 'Código',
    sendConfirm: 'Confirmar',
    emailInvalid: 'Email inválido',
    phoneInvalid: 'Por favor ingrese un número de teléfono válido',
    codeIncorrect: 'Términos El código de confirmación es incorrecto',
    codeSuccess:
      'El cliente confirmó la recepción y la comprensión de los Términos de EarthLink que se le enviaron.',
    emailVerify:
      'Correo electrónico o dominio no válido. Vuelva a introducir la dirección de correo electrónico',
    emailMessage:
      'El formato del correo electrónico es correcto, pero verifique su exactitud con el Cliente',
      processingHandling:'Tarifa de procesamiento y manejo',
    serviceAutoActivates: 'El servicio se activa automáticamente 10 días después de la entrega del Equipo',
  },
};

const appConfig = window.AppConfig || {};

export const config = {
  API_ENDPOINT:
    process.env.REACT_APP_API_ENDPOINT || appConfig.REACT_APP_API_ENDPOINT,
  APP_ENV: process.env.REACT_APP_ENV || appConfig.REACT_APP_ENV || 'test',
  SERVICEABILITY_BASE_URL:
    appConfig.REACT_APP_SERVICEABILITY_BASE_URL ||
    process.env.REACT_APP_SERVICEABILITY_BASE_URL,
  RESIDENTIAL_PARTNER: 'elnkafil',
  VAS_PROMO_CODE: '07427',
  SECURITY_TOOLS_URL:
    appConfig.REACT_APP_SECURITY_TOOLS_URL ||
    process.env.REACT_APP_SECURITY_TOOLS_URL ||
    'https://www.earthlink.net/security-and-tools/',
  CGM_ENDPOINT:
    appConfig.REACT_APP_CGM_ENDPOINT ||
    process.env.REACT_APP_CGM_ENDPOINT ||
    '',
  CGM_TOKEN:
    appConfig.REACT_APP_CGM_TOKEN || process.env.REACT_APP_CGM_TOKEN || '',
  SMARTY_AUTOCOMPLETE:
    appConfig.REACT_APP_SMARTY_AUTOCOMPLETE ||
    process.env.REACT_APP_SMARTY_AUTOCOMPLETE,
  SHOW_SCHEDULE_APPT:
    appConfig.REACT_APP_SHOW_SCHEDULE_APPT ||
    process.env.REACT_APP_SHOW_SCHEDULE_APPT ||
    'false',
  REACH_IFRAME:
    appConfig.REACT_APP_REACH_IFRAME ||
    process.env.REACT_APP_REACH_IFRAME ||
    'false',
  RECAPTCHA_SITE_KEY:
    appConfig.REACT_APP_RECAPTCHA_SITE_KEY ||
    process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  PROMO_OVERRIDE:
    appConfig.REACT_APP_PROMO_OVERRIDE ||
    process.env.REACT_APP_PROMO_OVERRIDE ||
    'true',
  SHOW_COUPON_CODE:
    appConfig.REACT_APP_SHOW_COUPON_CODE ||
    process.env.REACT_APP_SHOW_COUPON_CODE ||
    'false',
  SHOW_SPLIT_PAYMENT:
    appConfig.REACT_APP_SHOW_SPLIT_PAYMENT ||
    process.env.REACT_APP_SHOW_SPLIT_PAYMENT ||
    'true',
  CHECK_EXISTING_CUSTOMER:
    appConfig.REACT_APP_CHECK_EXISTING_CUSTOMER ||
    process.env.REACT_APP_CHECK_EXISTING_CUSTOMER ||
    'true',
  SHOW_PSWD_CQAS:
    appConfig.REACT_APP_SHOW_PSWD_CQAS ||
    process.env.REACT_APP_SHOW_PSWD_CQAS ||
    'false',
  CHARGE_MRC_POS:
    appConfig.REACT_APP_CHARGE_MRC_POS ||
    process.env.REACT_APP_CHARGE_MRC_POS ||
    'true',
  SHOW_SMS_SECURE_PAYMENT:
    appConfig.REACT_APP_SHOW_SMS_SECURE_PAYMENT ||
    process.env.REACT_APP_SHOW_SMS_SECURE_PAYMENT ||
    'true',
  SHOW_BUNDLES:
    appConfig.REACT_APP_SHOW_BUNDLES ||
    process.env.REACT_APP_SHOW_BUNDLES ||
    'true',
  SHOW_TERM_CONTRACT:
    process.env.REACT_APP_SHOW_TERM_CONTRACT ||
    appConfig.REACT_APP_SHOW_TERM_CONTRACT ||
    true,
  SHOW_ACP_CHECKBOX:
    process.env.REACT_APP_SHOW_ACP_CHECKBOX ||
    appConfig.REACT_APP_SHOW_ACP_CHECKBOX ||
    'true'
};

export const chasePaymentUrls = {
  encryption:
    appConfig.REACT_APP_CHASEPAYMENT_URL_ENCRYPTION ||
    process.env.REACT_APP_CHASEPAYMENT_URL_ENCRYPTION ||
    'n/a',
  getKey:
    appConfig.REACT_APP_CHASEPAYMENT_URL_GETKEY ||
    process.env.REACT_APP_CHASEPAYMENT_URL_GETKEY ||
    'n/a',
};

export const reactAppVersion =
  appConfig.REACT_APP_VERSION || process.env.REACT_APP_VERSION || '1.0';
