export const sessionKeys = {
  affiliateID: 'affiliateID',
  agentId: 'agentId',
  agentName: 'agentName',
  sessionOrderId: 'sessionOrderId',
  phoneNum: 'phoneNum',
  promoCode: 'promoCode',
  initialPromoCode: 'initialPromoCode',
  businessPromoCode: 'businessPromoCode',
  affiliateSupportTfn: 'affiliateSupportTfn',
  consumerSalesTfn: 'consumerSalesTfn',
  consumerSupportTfn: 'consumerSupportTfn',
  mobileFlag: 'mobileFlag',
  vasFlag: 'vasFlag',
  businessFlag: 'businessFlag',
  ebbFlag: 'ebbFlag',
  custId: 'custId',
  sessionReachUrl: 'sessionReachUrl',
  securityQue:'securityQue',
  securityAns:'securityAns',
  callLogId:'callLogId',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  groups: 'groups',
  store: 'store',
  loginEmailAttempts: 'loginEmailAttempts',
  prequalErrorCountValue: 'prequalErrorCountValue',
};
