import { css } from '@emotion/core';
import { SyncLoader, ClipLoader } from 'react-spinners';
import React from 'react';

const override = css`
    display: block;
    margin: 0 auto;
`;

const center = css`
  position: absolute;
  top: 50%;
  left: 50%;

  @media(max-width: 575px) {
    left: 40%!important;
  }
`

export const Loader = (props) => {
  return (
    <SyncLoader
      css={props.position === 'center' ? center : override}
      sizeUnit={'px'}
      size={15}
      color={'#007dba'}
      loading={true}
    />
  )
};

export const Spinner = ({ size }) => {
  return (
    <ClipLoader
      size={size || 15}
      color="#007dba"
    />
  )
};
