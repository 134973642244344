import React from 'react';
import { getAgentId } from '../../common/common';

const loggedIn = getAgentId();
const TopNavBar = () => {

  return (
    <div className="topHeader mob-show">
      <div className="topHeaderWrapper">
        {
          loggedIn ?
            <div className="navbar-text affId">
              <a href="tel:866-383-3080">866-383-3080</a>
            </div> : ''
        }
        <ul>
          <li>
            <a href="https://www.facebook.com/earthlink/" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/EarthLink" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-twitter-square" aria-hidden="true" href="https://twitter.com/EarthLink" target="_blank">
              </i>
            </a>
          </li>
          <li>
            <a href="http://linkedin.com/company/earthlink-internet/" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-linkedin-square" aria-hidden="true">
              </i>
            </a>
          </li>
        </ul>
        <div className="topEmail">
          <a href="https://webmail.earthlink.net/">
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <span>WebMail</span>
          </a>
        </div>

        <div className="mob-button free-consult-nav">
          <div>Need Help? <br />Call Affiliate Support</div>
          <button type="button" className="btn regBtn-grid"><a href="tel:833-957-1480">833-957-1480</a></button>
        </div>
      </div>
    </div>
  )
}
export default TopNavBar;
