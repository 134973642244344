import * as types from '../actions/Types';
import { getAccessToken, setAccessToken, getRefreshToken, getAgentId, setAgentId, setAffiliateID, setPromoCode, setBusinessPromoCode, setRefreshToken, getAgentName, getAffiliateID, getPromoCode, getBusinessPromoCode, getAffiliateSupportTfn, getConsumerSalesTfn, getConsumerSupportTfn, getVasFlag, setAgentName, setAffiliateSupportTfn, setConsumerSalesTfn, setConsumerSupportTfn, setVasFlag, getBusinessFlag, setBusinessFlag, getInitialPromoCode, setInitialPromoCode, getEbbFlag, setEbbFlag, getGroups, setGroups, getLoginEmailAttempts, setLoginEmailAttempts, setMobileFlag, setReachUrl } from '../../common/common';

export const initialState = {
  agentId: getAgentId(),
  agentName: getAgentName(),
  affiliateID: getAffiliateID(),
  promoCode: getPromoCode(),
  initialPromoCode: getInitialPromoCode(),
  businessPromoCode: getBusinessPromoCode(),
  affiliateSupportTfn: getAffiliateSupportTfn(),
  consumerSalesTfn: getConsumerSalesTfn(),
  consumerSupportTfn: getConsumerSupportTfn(),
  vasFlag: getVasFlag(),
  businessFlag: getBusinessFlag(),
  ebbFlag: getEbbFlag(),
  groups: getGroups() || [],
  roles: [],
  auth: getAccessToken(),
  refreshToken: getRefreshToken(),
  loginSuccess: false,
  loginFailed: false,
  loading: false,
  validId: false,
  submitErr: false,
  maintenanceOn: false,
  loginEmailAttempts: getLoginEmailAttempts() || [],
  partner: '',
  subPartner: '',
  prequalErrorCount: 0,
}

export default function LoginReducer(state = initialState, action) {
  let newState = { ...state };

  switch (action.type) {

    case types.LOGIN_FULFILLED:
      newState.loading = false;
      if (action.payload && action.payload.data) {
        let { partnerId, agentId, agentName, accessToken, promoCode, businessPromoCode, refreshToken, affiliateSupportTfn, consumerSalesTfn, consumerSupportTfn, vasFlag, businessFlag, ebbFlag, groups, department, companyName, mobileFlag } = action.payload.data;
        newState.affiliateID = partnerId;
        newState.agentId = agentId;
        newState.agentName = agentName;
        newState.promoCode = promoCode;
        newState.initialPromoCode = promoCode;
        newState.businessPromoCode = businessPromoCode;
        newState.affiliateSupportTfn = affiliateSupportTfn;
        newState.consumerSalesTfn = consumerSalesTfn;
        newState.consumerSupportTfn = consumerSupportTfn;
        newState.vasFlag = vasFlag;
        newState.businessFlag = businessFlag;
        newState.ebbFlag = ebbFlag;
        newState.validId = true;
        newState.auth = `Bearer ${accessToken}`;
        newState.refreshToken = refreshToken;
        newState.partner = department;
        newState.subPartner = companyName;
        setReachUrl('');
        setAgentId(agentId);
        setAgentName(agentName);
        setAffiliateID(partnerId);
        setPromoCode(promoCode);
        setInitialPromoCode(promoCode);
        setBusinessPromoCode(businessPromoCode);
        setAffiliateSupportTfn(affiliateSupportTfn);
        setConsumerSalesTfn(consumerSalesTfn);
        setConsumerSupportTfn(consumerSupportTfn);
        setVasFlag(vasFlag);
        setBusinessFlag(businessFlag);
        setEbbFlag(ebbFlag);
        setAccessToken(newState.auth);
        setRefreshToken(refreshToken);
        setMobileFlag(mobileFlag);
        if (groups) {
          setGroups(groups);
          newState.groups = groups;
        }
        newState.loginEmailAttempts = [];
      }
      else {
        newState.loginFailed = true;
        newState.submitErr = true;
      }
      return newState;

    case types.LOGIN_PENDING:
      newState.loading = true;
      return newState;

    case types.LOGIN_REJECTED:
      newState.loading = false;
      newState.loginFailed = true;
      if (action.payload.response.data.code === 503) {
        newState.maintenanceOn = true;
      } else {
        newState.submitErr = true;
      }
      return newState;

    case types.LOGOUT:
      newState.agentId = null;
      newState.validId = false;
      return newState;

    case types.UPDATE_TOKENS:
      if (action.tokens && action.tokens.refreshToken && action.tokens.accessToken) {
        let { accessToken, refreshToken } = action.tokens;
        newState.auth = `Bearer ${accessToken}`;
        newState.refreshToken = refreshToken;
        setAccessToken(newState.auth);
        setRefreshToken(refreshToken);
      }
      else {
        newState.loginFailed = true;
        newState.submitErr = true;
      }
      return newState;
    case types.SET_LOGIN_EMAILS:
      if (action.data) {
        newState.loginEmailAttempts = action.data;
        setLoginEmailAttempts(JSON.stringify(newState.loginEmailAttempts));
      }
      return newState;

    case types.SET_SUBMIT_ERROR:
      newState.submitErr = action.data;
      return newState;

    case types.PREQUAL_ERROR_COUNT:
      newState.prequalErrorCount = action.data;
      return newState;

    default: { return state }
  }
}