import * as types from './Types'
import { config } from '../../util/util'
import { api } from '../../util/axios'

export const login = (loginPayload) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/login`,
    data: loginPayload
  })
  return {
    type: types.LOGIN,
    payload: request
  }
}

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  return {
    type: types.LOGOUT,
  }
}

export const updateTokens = (tokens) => {
  return {
    type: types.UPDATE_TOKENS,
    tokens
  }
}

export const setLoginEmails = (data) => ({
  type: types.SET_LOGIN_EMAILS,
  data,
});

export const setSubmitError = (data) => ({
  type: types.SET_SUBMIT_ERROR,
  data,
});

export const setPrequalErrorCount = (data) => ({
  type: types.PREQUAL_ERROR_COUNT,
  data,
});