import { sessionKeys } from '../helpers/sessionKeysEnum';
import { EBB_DISCOUNT } from '../redux/actions/Types.js';
import { getServiceTerm } from '../../src/helpers/OrderHelperFunctions';
import { CGMRegex, checkUrlRegex } from '../util/regex';
import { config } from '../util/util';
import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York')

export const getAccessToken = () => {
  return sessionStorage.getItem(sessionKeys.accessToken);
};
export const setAccessToken = (value) => {
  sessionStorage.setItem(sessionKeys.accessToken, value);
}
const replaceUrl = (url) => {
  let href = url?.toLowerCase()?.replace(',', '');
  const webAddress = href;
  if (!/^https?:\/\//i.test(url)) {
    href = `https://${webAddress}`;
  }
  return `<a href="${href?.trim()}" target="_blank">${webAddress?.trim()}</a>${url?.includes(',') ? ', ' : ' '}`;
}

export const replaceURLWithHTMLLinks = (text) => {
  // Check string for any url patterns and wrap them in anchor tags
  return text?.replace(checkUrlRegex, replaceUrl);
};

export const getRefreshToken = () => {
  return sessionStorage.getItem(sessionKeys.refreshToken);
};

export const setRefreshToken = (value) => {
  sessionStorage.setItem(sessionKeys.refreshToken, value);
}

export const getAgentId = () => {
  return sessionStorage.getItem(sessionKeys.agentId);
};
export const setAgentId = (value) => {
  sessionStorage.setItem(sessionKeys.agentId, value);
}

export const getAgentName = () => {
  return sessionStorage.getItem(sessionKeys.agentName);
};
export const setAgentName = (value) => {
  sessionStorage.setItem(sessionKeys.agentName, value);
}

export const getAffiliateID = () => {
  return sessionStorage.getItem(sessionKeys.affiliateID);
};
export const setAffiliateID = (value) => {
  sessionStorage.setItem(sessionKeys.affiliateID, value);
}

export const getPromoCode = () => {
  return sessionStorage.getItem(sessionKeys.promoCode);
};
export const setPromoCode = (value) => {
  sessionStorage.setItem(sessionKeys.promoCode, value);
}

export const getInitialPromoCode = () => {
  return sessionStorage.getItem(sessionKeys.initialPromoCode);
};
export const setInitialPromoCode = (value) => {
  sessionStorage.setItem(sessionKeys.initialPromoCode, value);
}


export const getBusinessPromoCode = () => {
  return sessionStorage.getItem(sessionKeys.businessPromoCode);
};
export const setBusinessPromoCode = (value) => {
  sessionStorage.setItem(sessionKeys.businessPromoCode, value);
}

export const getAffiliateSupportTfn = () => {
  return sessionStorage.getItem(sessionKeys.affiliateSupportTfn);
};
export const setAffiliateSupportTfn = (value) => {
  sessionStorage.setItem(sessionKeys.affiliateSupportTfn, value);
};

export const getConsumerSalesTfn = () => {
  return sessionStorage.getItem(sessionKeys.consumerSalesTfn);
};
export const setConsumerSalesTfn = (value) => {
  sessionStorage.setItem(sessionKeys.consumerSalesTfn, value);
};

export const getConsumerSupportTfn = () => {
  return sessionStorage.getItem(sessionKeys.consumerSupportTfn);
};
export const setConsumerSupportTfn = (value) => {
  sessionStorage.setItem(sessionKeys.consumerSupportTfn, value);
};

export const getVasFlag = () => {
  return sessionStorage.getItem(sessionKeys.vasFlag);
};
export const setVasFlag = (value) => {
  sessionStorage.setItem(sessionKeys.vasFlag, value);
};

export const getSessionOrderId = () => {
  return sessionStorage.getItem(sessionKeys.sessionOrderId);
};
export const setSessionOrderId = (value) => {
  sessionStorage.setItem(sessionKeys.sessionOrderId, value);
};
export const clearSessionOrderId = () => {
  sessionStorage.removeItem(sessionKeys.sessionOrderId);
};

//whi Reach URL
export const getReachUrl = () => {
  return sessionStorage.getItem(sessionKeys.sessionReachUrl);
};
export const setReachUrl = (value) => {
  sessionStorage.setItem(sessionKeys.sessionReachUrl, value);
};
export const clearReachUrl = () => {
  sessionStorage.setItem(sessionKeys.sessionReachUrl, '');
};



export const getBusinessFlag = () => {
  return sessionStorage.getItem(sessionKeys.businessFlag);
};
export const setBusinessFlag = (value) => {
  sessionStorage.setItem(sessionKeys.businessFlag, value);
};

export const getEbbFlag = () => {
  return sessionStorage.getItem(sessionKeys.ebbFlag);
};

export const setEbbFlag = (value) => {
  sessionStorage.setItem(sessionKeys.ebbFlag, value);
};

export const setCustId = (value) => {
  sessionStorage.setItem(sessionKeys.custId, value);
}
export const getCustId = () => {
  return sessionStorage.getItem(sessionKeys.custId);
}
export const setCallLogId = (value) => {
  sessionStorage.setItem(sessionKeys.callLogId, value);
}
export const getCallLogId = () => {
  return sessionStorage.getItem(sessionKeys.callLogId);
}
export const getGroups = () => {
  const groups = (sessionKeys.groups && sessionStorage.getItem(sessionKeys.groups)) || '';
  return groups.split(',');
};
export const setGroups = (value) => {
  sessionStorage.setItem(sessionKeys.groups, value);
};

export const setStoreAddr = (value) => {
  sessionStorage.setItem(sessionKeys.store, value);
}

export const getStoreAddr = () => {
  return sessionStorage.getItem(sessionKeys.store) || '';
}

export const setLoginEmailAttempts = (value) => {
  return localStorage.setItem(sessionKeys.loginEmailAttempts, value);
}

export const getLoginEmailAttempts = () => {
  return localStorage.getItem(sessionKeys.loginEmailAttempts) || '';
}

export const getMobileFlag = () => {
  return sessionStorage.getItem(sessionKeys.mobileFlag) || '';
};

export const setMobileFlag = (value) => {
  sessionStorage.setItem(sessionKeys.mobileFlag, value);
};

export const setPrequalErrorCount = (value) => {
  sessionStorage.setItem(sessionKeys.prequalErrorCountValue, value);
}; // set prequal error count for a session

export const getPrequalErrorCount = () => {
  return sessionStorage.getItem(sessionKeys.prequalErrorCountValue) || '0' ;
}; // get prequal error count for a session

//TO SAVE FIRST SET OF SECURITY QUESTION TO SESSION STORAGE
export function setSecurityQuestion(question, answer) {
  sessionStorage.setItem(sessionKeys.securityQue, question);
  sessionStorage.setItem(sessionKeys.securityAns, answer);
}
//TO GET FIRST SET OF SECURITY QUESTION TO SESSION STORAGE
export function getSecurityQuestion(question, answer) {
  let securityQuestionModel = {};
  securityQuestionModel.question = sessionStorage.getItem(sessionKeys.securityQue, question);
  securityQuestionModel.answer = sessionStorage.getItem(sessionKeys.securityAns, answer);
  return securityQuestionModel;
}
export const getValid$Cost = (cost, quantity) => {
  if (!isUndefinedNullOrEmpty(cost) && cost.toString().indexOf('$') < 0) {
    if (quantity) {
      return '$' + (cost * quantity).toFixed(2);
    }
    return '$' + cost;
  }
  return cost;
};

export const getCost = (priceValue) => !isNaN(Number(priceValue)) ? `$${Number(priceValue).toFixed(2)}` : '';

export const getValidIntCost = (cost) => {
  if (isUndefinedNullOrEmpty(cost))
    return 0;

  if (!isUndefinedNullOrEmpty(cost) && cost.toString().indexOf('$') < 0) {
    return Number(cost);
  }
  else {
    while (!isUndefinedNullOrEmpty(cost) && cost.toString().indexOf('$') >= 0) {
      cost = cost.substring(1);
    }
    return Number(cost.split(' ')[0]);
  }
}

export const getPromoPrice = (data, serviceTerms) => {
  if (serviceTerms?.length > 0) {
    const getServX4YPromo = serviceTerms
      .filter(service => service.servServRef === data.servRef)[0]
      ?.servX4Y.find(item => item.servType === 'Promo');
    if (Number(getServX4YPromo?.servX4YAmount) > 0) {
      return getValid$Cost((getServX4YPromo?.servX4YAmount), data?.quantity);
    } else {
      return '';
    }
  }
  return '';
}
export const servicePriceHandler = (data, serviceTerm) => {
  return getValid$Cost(getServiceTerm(serviceTerm, data)?.servX4YAmount || Number(data?.price), data?.quantity);
}

export const getMrcAmount = (data, serviceTerm, isBundleAdded = false) => {
  return Number(
    serviceTerm
      ?.find(item => item?.servServRef === data?.servRef)
      ?.servX4Y?.find(item => isBundleAdded ? item?.servType.toLowerCase() === 'service' : item?.servType.toLowerCase() === 'mrcpos')?.servX4YAmount,
  ) * (data?.quantity || 1) || 0;
};

//CHECK FOR VALUE TO BE EMPTY, NULL OR UNDEFINED
export function isUndefinedNullOrEmpty(value) {
  if (value === undefined || value === '' || value === null) {
    return true
  }
  return false
}

export function isEmpty(value) {
  if (value === '') {
    return true
  }
  return false
}

export function getSegregatedAddress(address) {
  let segregatedAddress = {};
  // Extract address fields by splitting
  const addressFields = address.split(', ');
  let i = 1;
  segregatedAddress.address1 = isUndefinedNullOrEmpty(addressFields[0]) ? '' : addressFields[0].trim();
  //if addressFields length < 5 then we assume address2 is not there
  if (addressFields.length === 5) {
    segregatedAddress.address2 = isUndefinedNullOrEmpty(addressFields[1]) ? '' : addressFields[1].trim();
    i = 2;
  } else {
    segregatedAddress.address2 = ''
  }
  segregatedAddress.city = isUndefinedNullOrEmpty(addressFields[i]) ? '' : addressFields[i].trim();
  segregatedAddress.state = isUndefinedNullOrEmpty(addressFields[i + 1]) ? '' : addressFields[i + 1].trim();
  segregatedAddress.zip = isUndefinedNullOrEmpty(addressFields[i + 2]) ? '' : addressFields[i + 2].trim();
  return segregatedAddress;
}
//input arg must be object of length 5 having addr1, addr2, city, state, zip in this order only.
export function getAggregatedAddress(addrObj, returnObj = false) {
  let aggregatedAddress = '';
  const len = !isUndefinedNullOrEmpty(addrObj) ? Object.keys(addrObj).length : 0;
  if (len > 0) {
    aggregatedAddress = `${isUndefinedNullOrEmpty(addrObj.address1) ? '' : (addrObj.address1 + ', ')}${isUndefinedNullOrEmpty(addrObj.address2) ? '' : (addrObj.address2 + ', ')}${isUndefinedNullOrEmpty(addrObj.city) ? '' : (addrObj.city + ', ')}${isUndefinedNullOrEmpty(addrObj.state) ? '' : (addrObj.state + ', ')}${isUndefinedNullOrEmpty(addrObj.zipCode) ? '' : (addrObj.zipCode).substring(0, 5)}`
    return returnObj ?
      {
        address: aggregatedAddress,
        hasMore: false
      }
      : aggregatedAddress;
  } else {
    return null
  }
}

// store address
export function getAggregatedStoreAddress(addrObj) {
  let brand = '';
  if (addrObj.brand) {
    brand = addrObj.brand.slice(0, 1).toUpperCase() + addrObj.brand.slice(1) + ' #';
  }
  let aggregatedAddress = brand;
  const len = !isUndefinedNullOrEmpty(addrObj) ? Object.keys(addrObj).length : 0;
  if (len > 0) {
    aggregatedAddress = aggregatedAddress + `${isUndefinedNullOrEmpty(addrObj.store_num) ? '' : (addrObj.store_num) + ' - '}${isUndefinedNullOrEmpty(addrObj.address) ? '' : (addrObj.address)}, ${isUndefinedNullOrEmpty(addrObj.city) ? '' : (addrObj.city)}, ${isUndefinedNullOrEmpty(addrObj.state) ? '' : (addrObj.state)}, ${isUndefinedNullOrEmpty(addrObj.zip_plus_4) ? '' : (addrObj.zip_plus_4).substring(0, 5)}`
    return aggregatedAddress;
  }
}

export function aggregateAddressAndSec(addrObj) {
  let aggregatedAddress = '';
  const len = !isUndefinedNullOrEmpty(addrObj) ? Object.keys(addrObj).length : 0;
  if (len > 0) {
    aggregatedAddress = `${isUndefinedNullOrEmpty(addrObj.address1) ? '' : (addrObj.address1 + ', ')}${isUndefinedNullOrEmpty(addrObj.address2) ? '' : (addrObj.address2 + ', ')}${(addrObj.entries) <= 1 ? '' : '(' + (addrObj.entries) + ' more entries) '}${isUndefinedNullOrEmpty(addrObj.city) ? '' : (addrObj.city + ', ')}${isUndefinedNullOrEmpty(addrObj.state) ? '' : (addrObj.state + ', ')}${isUndefinedNullOrEmpty(addrObj.zipCode) ? '' : (addrObj.zipCode)}`
    return {
      address: aggregatedAddress,
      hasMore: addrObj.entries <= 1 ? false : true,
    };
  } else {
    return null
  }
}

export const getValidAcctEmail = (email, isElnkMailboxReq) => {
  let validId = email;
  if (!isUndefinedNullOrEmpty(email) && isElnkMailboxReq) {
    validId = email.split('@')[0].replace(/[^a-zA-Z0-9-_]/g, '');
    while ((!isNaN(Number(validId.charAt(0))) || ['-', '_'].includes(validId.charAt(0))) && validId !== '') {
      validId = validId.substr(1, validId.length - 1)
    }
    return validId.substring(0, 16);
  }
  return validId;
};

export const calcDiscountedPrice = (price) => {
  const amt = price - EBB_DISCOUNT;
  if (amt < 0) {
    return 0;
  }
  return Number(amt.toFixed(2));
};
export function defineParam(a, b, c) {
  if (a) {
    return b
  }
  return c
}

export const openCGMWebApp = async (
  agentId, normalizedAddress, sessionId,
) => {
  const { address1, address2, city, state, zipCode } = normalizedAddress;
  let zip = zipCode;

  if (zipCode.length > 5) {
    zip = zipCode.slice(0, 5);
  }
  agentId = agentId.slice(0, 8);

  const address01 = address1.replace(CGMRegex, '');
  const address02 = (address2 && address2.replace(CGMRegex, '')) || '';

  const customerAgentCode = config.APP_ENV === 'production' ? `el-${agentId}` : 'Dev_test';
  const payload = `Token=${config.CGM_TOKEN
    }&CustomerAgentSessionId=${sessionId}&customerAgentCode=${customerAgentCode}&primaryAddress01=${address01}&primaryAddress02=${address02}&primaryCity=${city}&primaryState=${state}&primaryZipCode=${zip}`;

  return fetch(`${config.CGM_ENDPOINT}`, {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: payload,
    mode: 'cors',
  }).then(response => response.json());
};

export const checkTollFreeNumber = (number) => {
  if (!isUndefinedNullOrEmpty(number)) {
    if (/^\+18((00|88|77|66|55|44|33)\d{7})$/.test(number)) {
      return true;
    }
    return false;
  }
  return false;
};

export const checkPlanQty = (plan) => {
  let servRef = ''
  if (plan.quantity > 1) {
    servRef = plan.servRef + (`,${plan.servRef}`).repeat(plan.quantity - 1)
    return servRef
  }
  return plan.servRef;
}

export const getWirelessServRef = (wirelessCart) => {
  let servRef = ''
  wirelessCart.forEach((item, i) => {
    servRef = i === 0 ? checkPlanQty(item) : servRef + `,${checkPlanQty(item)}`;
  })
  return servRef
}

export const defineParamCheck = (isVasOrder, a, b) => {
  if (isVasOrder || isUndefinedNullOrEmpty(a)) {
    return b
  }
  return a
}

export function defineParamAddressSync(a) {
  if (!isUndefinedNullOrEmpty(a)) {
    return a
  }
  return ''
}

export const getRefValue = (ref, defaultValue = '') => {
  if (ref && ref.current) {
    if (ref.current.state) {
      return ref.current.state.value
    } else if (ref.current.value) {
      return ref.current.value
    }
  }
  return defaultValue;
};

// convert Date time  seprated using 'to' (date to startTime to endTime) to UTC date time
// 2021-12-06 to 2:00 pm to 4:00 pm ==> 2021-12-06T14:00:00.000Z|2021-12-06T16:00:00.000Z
export const convertDateTimeToUTC = (dateTime) => {
  if (isUndefinedNullOrEmpty(dateTime)) {
    return '';
  }
  const segregatedDate = dateTime.split('to');
  const startTime = moment(
    `${segregatedDate[0]} ${segregatedDate[1]}`, 'YYYY-MM-DD h:mm a'
  ).format('YYYY-MM-DD HH:mm:ss');
  const endTime = moment(
    `${segregatedDate[0]} ${segregatedDate[2]}`, 'YYYY-MM-DD h:mm a'
  ).format('YYYY-MM-DD HH:mm:ss');
  const utcStartDateTime = moment.tz(startTime, 'YYYY-MM-DD HH:mm:ss').toISOString();
  const utcEndDateTime = moment.tz(endTime, 'YYYY-MM-DD HH:mm:ss').toISOString();
  return `${utcStartDateTime}|${utcEndDateTime}`;
}

// convert Date time  seprated using 'to' (date to startTime to endTime) to UTC date time
// 2021-12-06T14:00:00.000Z|2021-12-06T16:00:00.000Z ==> 2021-12-06 between 2:00 pm and 4:00 pm
export const convertInstallWindowToReadableString = (window) => {
  if (isUndefinedNullOrEmpty(window)) {
    return '';
  }
  const getScheduleData = window.split('|');
  const getDateTime = getScheduleData.filter((splitTime) => splitTime !== '0');
  const date = moment(getScheduleData[0]).format('MMMM DD, YYYY');

  return `${date} between ${moment.utc(getDateTime[0]).format('h:mm a')} and ${moment
    .utc(getDateTime[1])
    .format('h:mm a')}`;
}

// TCS Links points

export const tcsLinks = () => {
  if (window.location.origin === 'https://aff.elnkdev.com') {
    return 'https://dev3.earthlink.serverguy.cloud'
  }
  if (window.location.origin === 'https://aff-qa.elnkdev.com') {
    return 'https://dev2-earthlink.serverguy.com'
  }
  if (window.location.origin === 'https://aff-staging.earthlink.com') {
    return 'https://dev1-earthlink.serverguy.com'
  }
  return 'https://www.earthlink.net'
}

export const getProductsCount = (products = []) => {
  return products.reduce((sum, { quantity }) => sum + (quantity || 1), 0);
};

export const showDiscountApplied = (wirelessPlanInfo, serviceTerm) => {
  return (getProductsCount(wirelessPlanInfo) === 1 &&
    getPromoPrice(wirelessPlanInfo[0], serviceTerm))
    ? true
    : false;
};

export const discountValue = (wirelessPlanInfo, serviceTerm) => {
  return (getValidIntCost(getServiceTerm(serviceTerm, wirelessPlanInfo[0])?.servX4YAmount || 0) - getValidIntCost(getPromoPrice(wirelessPlanInfo[0], serviceTerm) || 0)).toFixed(2)
}

const formatNumberWithCommas = (cost) => {
  if (!cost) return '';
  const actualCost = cost?.toString()?.replace(/[^0-9.]/g, '');
  return parseFloat(actualCost)?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
export const internetServiceETF = (serviceETF) => {
  const amtRegex = /\$(\d+(\.\d{2})?)/gm;
  const foundETF = serviceETF?.match(amtRegex);
  if (!foundETF) {
    return '';
  }
  if (foundETF?.length > 1) {
    console.warn('More than one amount found in servEtfDesc.');
  }
  return `$${formatNumberWithCommas(foundETF[0])}`;
};