/* eslint-disable no-mixed-operators */
import React from 'react';
import * as $ from 'axios';
import { Select } from 'antd';
import { isUndefinedNullOrEmpty, getAggregatedAddress, aggregateAddressAndSec } from '../../common/common';
import 'antd/es/select/style/index.css';
const { Option } = Select;
export default class AddressSuggestionSelect extends React.Component {
  state = {
    data: [],
    value: isUndefinedNullOrEmpty(this.props.val) ? '' : this.props.val,
    addressProps: [],
    multipleEntries: false,
    globalDate: null,
  };

  handleSearch = (value) => {
    let suggestionsArray = [];
    let addressArray = [];
    if (value.length > 3 && (value.replace(/\s/g, '').length)) {
      if (value.length === 4 || this.state.globalDate && (Date.now() - this.state.globalDate) / 1000 >= .5) {
        this.props.OrderActions.smartyAutoComplete(value)
        .then((data) => {
          if (data?.value?.data?.suggestions?.length) {
            data.value.data.suggestions.forEach((suggestion, index) => {
              const { street_line, secondary, city, state, zipcode, entries } = suggestion;
              suggestionsArray[index] = aggregateAddressAndSec({
                'address1': street_line,
                'address2': secondary,
                'entries': entries,
                'city': city,
                'state': state,
                'zipCode': zipcode
              });
              addressArray[index] = {
                'address1': street_line,
                'address2': secondary,
                'city': city,
                'state': state,
                'zip': zipcode
              }
              this.setState({
                data: suggestionsArray,
                addressProps: addressArray,
                value: '',
                globalDate: Date.now()
              });
            })
          } else if (data?.value?.data?.suggestions === null || !data?.value?.data?.suggestions?.length) {
            $.get(`https://us-autocomplete.api.smartystreets.com/suggest?prefix=${value}&key=674755572382025`)
              .then((addressApiRes) => {
                if (addressApiRes.data.suggestions) {
                  addressApiRes.data.suggestions.forEach((suggestion) => {
                    const { street_line, city, state } = suggestion
                    $.get(`https://us-street.api.smartystreets.com/street-address?candidates=10&match=enhanced&key=674755572382025&street=${encodeURIComponent(street_line)}&city=${city}&state=${state}`)
                      .then((response) => {
                        if (!isUndefinedNullOrEmpty(response.data[0].components.zipcode)) {
                          const {
                            primary_number = '',
                            street_name = '',
                            street_suffix = '',
                            secondary_number = '',
                            secondary_designator = '',
                            city_name = '',
                            state_abbreviation = '',
                            zipcode,
                          } = response.data[0].components;

                          let address1, address2 = '';

                          if (primary_number || street_name || street_suffix) address1 = `${primary_number} ${street_name} ${street_suffix}`
                          if (secondary_designator || secondary_number) address2 = `${secondary_designator} ${secondary_number}`

                          // CHECK IF INCLUDES STATE AND ZIP
                          if (address2.includes(state_abbreviation) || address2.includes(zipcode)) {
                            address2 = ''
                          }

                          // SET SUGGESTIONS
                          suggestionsArray.push(aggregateAddressAndSec({
                            'address1': address1,
                            'address2': address2,
                            'entries': 0,
                            'city': city_name,
                            'state': state_abbreviation,
                            'zipCode': zipcode
                          }));
                          addressArray.push({
                            'address1': address1,
                            'address2': address2,
                            'city': city_name,
                            'state': state_abbreviation,
                            'zip': zipcode
                          });
                          this.setState({
                            data: suggestionsArray,
                            addressProps: addressArray,
                            value: '',
                            globalDate: Date.now()
                          });
                        }
                      })
                  });
                }
              })
          }
        })
      }
    }
  };

  handleChange = (value) => {
    if (!isUndefinedNullOrEmpty(value)) {
      const selectedAddress = this.state.data[value].address;
      if (selectedAddress.includes('more entries')) {
        let multipleAdd = true;
        const modifiedAddress = selectedAddress.replace(' more entries', '');
        const suggestionsArray = [];
        let addressArray = [];
        this.props.OrderActions.smartyAutoComplete(modifiedAddress.slice(0, modifiedAddress.indexOf('(') > 0 ? modifiedAddress.indexOf('(') : undefined), modifiedAddress)
          .then((data) => {
            if (data.value.data.suggestions) {
              data.value.data.suggestions.forEach((suggestion, index) => {
                const { street_line, secondary, city, state, zipcode } = suggestion;
                suggestionsArray[index] = getAggregatedAddress({
                  'address1': street_line,
                  'address2': secondary,
                  'city': city,
                  'state': state,
                  'zipCode': zipcode
                }, true);
                addressArray[index] = {
                  'address1': street_line,
                  'address2': secondary,
                  'city': city,
                  'state': state,
                  'zip': zipcode
                }
              })
            }
            this.setState({
              value: '',
              data: suggestionsArray,
              addressProps: addressArray,
              multipleEntries: multipleAdd,
            });
          })
      }
      else {
        this.setState({
          value,
          multipleEntries: false,
        });
        if (this.props.setSearchAddress) {
          this.props.setSearchAddress(selectedAddress, this.state.addressProps[value]);
        }
        if (this.props.setBilling) {
          this.props.setBilling(this.state.addressProps[value]);
        }
      }
    } else {
      this.setState({ data: [] })
    }
  };

  render() {
    const { data, value, multipleEntries } = this.state;
    const selectOptions = {
      className: 'addrSelect ' + this.props.className,
      autoFocus: this.props.autoFocus,
      disabled: this.props.loading || this.props.disabled,
      filterOption: false,
      notFoundContent: null,
      onChange: this.handleChange,
      onSearch: this.handleSearch,
      showAction: ['focus', 'click'],
      showArrow: false,
      showSearch: true,
      size: 'large',
      value: value,
    }
    if (multipleEntries) {
      selectOptions.open = true
    }
    // if (this.props.parentComp === 'servCheck') {
    return (
      <Select
        {...selectOptions}
      >
        <Option selected hidden key="default" value="">
          <i className="fa fa-map-marker fa-2" aria-hidden="true" />
          &nbsp;
          <span className="placeholder">
            {
              this.props.parentComp === 'servCheck' ?
                'Enter Street Address' : this.props.parentComp === 'entInfo' ?
                  'Billing Address' : 'Enter Address'
            }
          </span>
        </Option>
        {
          data !== [] && data.map((d, i) => <Option
            className={d.hasMore && 'boldText'}
            key={i}>{d.address}</Option>)
        }
      </Select>
    );
  }
}
