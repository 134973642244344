import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Calendar, Icon, Carousel } from 'antd';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { config } from '../../util/util';
import { Loader } from '../common/Loader';
import { convertDateTimeToUTC } from '../../common/common';
import {
  updateOrder,
  getScheduleAppt,
  setReqInstallDateTime,
} from '../../redux/actions/OrderAction';
import Modal from 'react-bootstrap/Modal';
moment.tz.setDefault('America/New_York');
const SHOW_SCHEDULE_APPT =
  config.SHOW_SCHEDULE_APPT &&
  config.SHOW_SCHEDULE_APPT.toLowerCase() === 'true';

const ScheduleInstallation = (props) => {
  const [time, setTime] = useState('');
  const [isTimeButton, setTimeButton] = useState([]);
  const [isSelectedDate, setSelectedDate] = useState('');
  const [selectedDateTime, setSelectedDateTime] = useState('');
  const [calenderSelectedDate, setCalenderSelectedDate] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [scheduleDateObj, setScheduleDateObj] = useState({});
  const [retryNumber, setRetryNumber] = useState(0);

  const { scheduleData, loading, OrderState, orderAccessToken } = useSelector((state) => ({
    scheduleData: state.OrderState.scheduleData,
    loading: state.OrderState.scheduleDataLoading,
    OrderState: state.OrderState,
    orderAccessToken: state.OrderState.orderAccessToken,
  }));
  const { serviceabilityCheck, planInfo, callLogId } = OrderState;
  const { extendedInfo, canScheduleAppt } = serviceabilityCheck;
  const extendedInfoSelected = extendedInfo?.serviceableService.find(
    (servService) => servService.productId === planInfo.servRef
  );
  const verizonCheck = extendedInfoSelected?.vendor.toString().toLowerCase().includes('verizon');
  const dispatch = useDispatch();
  const retryScheduling = () => {
    const { planInfo, callLogId } = OrderState;
    dispatch(
      getScheduleAppt({
        userName: 'xmlc',
        serviceReference: planInfo.servRef,
        callLogId,
      })
    ).catch(() => { });
    setRetryNumber((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (SHOW_SCHEDULE_APPT) {
      if (
        extendedInfoSelected &&
        canScheduleAppt &&
        canScheduleAppt.toLowerCase() === 'yes'
      ) {
        dispatch(
          getScheduleAppt({
            userName: 'xmlc',
            serviceReference: planInfo.servRef,
            callLogId,
          })
        );
      } else {
        // This will take user to next step if env not set.
        props.onNext();
      }
      return;
    }
    // This will take user to next step if env not set.
    props.onNext();
    // dependency Array is empty, because this code needs to be run only once.
  }, []);

  useEffect(() => {
    const dateObj = {};
    if (SHOW_SCHEDULE_APPT) {
      if (
        scheduleData?.installWindow &&
        scheduleData?.installWindow.length &&
        !loading
      ) {
        setShowErrorModal(false);
        scheduleData.installWindow.map((items) => {
          const getScheduleData = items.split('|');
          const date = moment(getScheduleData[0]).format('YYYY-MM-DD');
          const getDateTime = getScheduleData.filter(
            (splitTime) => splitTime !== '0'
          );

          const startTime = `${moment
            .utc(getDateTime[0])
            .format('h:mm a')} to ${moment
              .utc(getDateTime[1])
              .format('h:mm a')}`;
          if (dateObj[date]) {
            dateObj[date].push(startTime);
          } else {
            dateObj[date] = [startTime];
          }
          return true;
        });
      } else if (!loading && scheduleData&& Object.keys(scheduleData).length) {
        setShowErrorModal(true);
        dispatch(
          updateOrder(
            {
              serviceError:
                scheduleData?.installStatus ||
                'Error while calling the /install-status',
            },
            orderAccessToken
          )
        );
      }

      if (Object.values(dateObj).length > 0) {
        setScheduleDateObj(dateObj);
        if (calenderSelectedDate === '') {
          setCalenderSelectedDate(Object.keys(dateObj)[0]);
          const getDefaultStartTime =
            dateObj &&
            dateObj[Object.keys(dateObj)[0]] &&
            dateObj[Object.keys(dateObj)[0]].map((dateItem) => {
              if (dateItem) {
                return dateItem;
              }
              return false;
            });
          setTimeButton(getDefaultStartTime);
          setSelectedDate(Object.keys(dateObj)[0]);
          let combineDate = '';
          getDefaultStartTime.map((timeVal) => {
            combineDate = `${Object.keys(dateObj)[0]} to ${timeVal}`;
          });
          if (combineDate) {
            setSelectedDateTime(combineDate);
          }
        }
      }
    }
  }, [calenderSelectedDate, scheduleData, loading, dispatch, orderAccessToken]);

  // HandleConfirm
  const handleConfirm = () => {
    dispatch(setReqInstallDateTime(convertDateTimeToUTC(selectedDateTime)));
    props.onNext();
  };

  const onSelect = (date) => {
    const selectedDate = moment(date).format('YYYY-MM-DD');
    const getstartTime =
      scheduleDateObj &&
      scheduleDateObj[selectedDate] &&
      scheduleDateObj[selectedDate].map((item) => {
        if (item) {
          return item;
        }
        return false;
      });
    setTimeButton(getstartTime);
    setSelectedDate(selectedDate);
    setSelectedDateTime('');
    setCalenderSelectedDate(selectedDate);
    if (time && selectedDateTime) {
      setTime('');
      setSelectedDateTime('');
    }
  };

  const disabledDate = (current) => {
    const disabledDates = Object.keys(scheduleDateObj).find((o) => {
      if (moment(o).format('YYYY-MM-DD') === moment(current).format('YYYY-MM-DD')) {
        return true;
      }
      return false;
    });

    if (disabledDates) {
      return false;
    }
    return true;
  };
  const getInitialDate = (selectedMon) => {
    for (let key in scheduleDateObj) {
      let d = moment(key);
      let n = d.format('M');
      if (parseInt(n) === selectedMon) {
        return key;
      }
    }
  };

  const handleModalBtn = () => {
    if (verizonCheck) {
      window.location.replace('/')
    }
    else {
      setShowErrorModal(false);
      props.onNext();
    }
  }

  // RENDER UI FOR CALENDER
  const renderCalenderUI = () => {
    return (
      <>
        {renderErrorModal()}
        {!showErrorModal && (
          <>
            <Row>
              <Col span={12}>
                <CalTitle>Appointment Preference</CalTitle>
                <StyledAntdCalender
                  fullscreen={false}
                  headerRender={({ value, onChange }) => {
                    let start =
                      Number(
                        moment(Object.keys(scheduleDateObj)[0]).format('MM')
                      ) - 1;
                    const end = Number(
                      moment(
                        Object.keys(scheduleDateObj)[
                        Object.keys(scheduleDateObj).length - 1
                        ]
                      ).format('MM')
                    );
                    const monthOptions = [];

                    if (start > end) {
                      for (let index = start; index < 12; index++) {
                        monthOptions.push(index);
                      }
                      start = 0;
                    }
                    for (let index = start; index < end; index++) {
                      monthOptions.push(index);
                    }
                    const month = moment(value).format('MMMM');

                    const handleChangeMonth = (op) => {
                      let selectedMonth;
                      if (op === '+') {
                        selectedMonth = (value.month() + 1) % 12;
                      } else if (op === '-') {
                        selectedMonth = value.month()
                          ? (value.month() % 12) - 1
                          : 11;
                      } else if (op === 'last') {
                        selectedMonth = monthOptions[monthOptions.length - 1];
                      } else if (op === 'first') {
                        selectedMonth = monthOptions[0];
                      }
                      selectedMonth = Math.abs(selectedMonth);
                      if (monthOptions.includes(selectedMonth)) {
                        // Set the new month
                        const newValue = value.clone();
                        newValue.month(selectedMonth);
                        onChange(newValue);
                        // Get Initial Date for new month selected
                        const initialDate = getInitialDate(selectedMonth + 1);
                        onSelect(initialDate);
                      }
                    };

                    return (
                      <div>
                        <Row className="schedule-header">
                          <Col span={5}>Month: &nbsp; </Col>
                          <Col span={1}>
                            <StyledIcon
                              type="double-left"
                              onClick={() => handleChangeMonth('first')}
                              className={
                                value.month() === monthOptions[0]
                                  ? 'hide-items'
                                  : 'show-inline-items'
                              }
                            />
                          </Col>
                          <Col span={1}></Col>
                          <Col span={1}>
                            <StyledIcon
                              type="left"
                              onClick={() => handleChangeMonth('-')}
                              className={
                                value.month() === monthOptions[0]
                                  ? 'hide-items'
                                  : 'show-inline-items'
                              }
                            />
                          </Col>
                          <Col span={10} className="text-center">
                            <span>{month}</span>
                          </Col>
                          <Col span={1}>
                            <StyledIcon
                              type="right"
                              onClick={() => handleChangeMonth('+')}
                              className={
                                value.month() ===
                                  monthOptions[monthOptions.length - 1]
                                  ? 'hide-items'
                                  : 'show-inline-items'
                              }
                            />
                          </Col>
                          <Col span={1}></Col>
                          <Col span={1}>
                            <StyledIcon
                              type="double-right"
                              onClick={() => handleChangeMonth('last')}
                              className={
                                value.month() ===
                                  monthOptions[monthOptions.length - 1]
                                  ? 'hide-items'
                                  : 'show-inline-items'
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                  disabledDate={disabledDate}
                  onSelect={onSelect}
                  value={moment(calenderSelectedDate)}
                />
              </Col>
              <Col span={12}>
                <CalTitle className="text-center schedule-right-header">
                  Technician&apos;s Arrival Window
                </CalTitle>
                {isTimeButton &&
                  isTimeButton.map((timeVal, k) => {
                    const combineDate = `${isSelectedDate} to ${timeVal}`;
                    return (
                      <TechArriveTimeBtn
                        onClick={() => {
                          setTime(combineDate);
                          setSelectedDateTime(combineDate);
                        }}
                        className={
                          time === combineDate
                            ? 'blue-border-btn mt-2 mb-3'
                            : 'grey-border-btn mt-2 mb-3'
                        }
                        key={k}
                      >
                        {time === combineDate ? (
                          <Icon type="check-circle" />
                        ) : (
                          ''
                        )}
                        {timeVal}
                      </TechArriveTimeBtn>
                    );
                  })}
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <button
                  type="button"
                  name="declineButton"
                  className={verizonCheck ? 'd-none' : 'btn btn-light w-100'}
                  onClick={props.onNext}
                >
                  Skip
                </button>
              </Col>
              <Col span={1}></Col>
              <Col span={11}>
                <button
                  type="button"
                  className="btn btn-primary modalButton w-100"
                  disabled={!(selectedDateTime !== '' && time !== '')}
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  // RENDER ERROR MODAL
  const renderErrorModal = () => {
    return (
      <Modal
        show={showErrorModal}
        className="modal"
        id="callSupport"
        backdrop="static"
        tabIndex="-1"
        role="dialog"
      >
        <Modal.Body>
          {verizonCheck ? 'Verizon appointment scheduling not available at this time. Please retry scheduling or try again later.  You cannot proceed with an order until scheduling is available.' : `${extendedInfoSelected?.vendor}  appointment scheduling not available at
          this time.   Proceed with order and customer should receive installation
          date via text and email within 2 business days.`}
        </Modal.Body>
        <Modal.Footer
          className={retryNumber < 3 ? 'justify-content-between' : ''}
        >
          {retryNumber < 3 && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={retryScheduling}
            >
              Retry Scheduling
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleModalBtn}
          >
            {verizonCheck ? 'Go to home' : 'continue'}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (loading) {
    return props.isSinglePage ?
      null :
      (
        <>
          <div className={'text-center w-100 mt-3'}>
            <Loader />
          </div>
          <div className="appear-span">
            <Carousel effect="fade" autoplay dots={false}>
              <div className={'text-center'}>
                <i className={'fas fa-2x fa-check spinFeedback mr-2'} />
                <span>
                  Checking for available Internet Installation appointments
                </span>
              </div>
            </Carousel>
          </div>
        </>
      );
  }

  if (SHOW_SCHEDULE_APPT) {
    return (
      <>
        {!props.isSinglePage && (
          <div className="card-header">
            <h3 className="serviceTitle mb-0">Schedule Internet Installation</h3>
          </div>
        )}
        <div className="panel panel-primary setup-content step" id="step-4">
          <div className="panel-body">{renderCalenderUI()}</div>
        </div>
      </>
    );
  }

  return null;
};

export default ScheduleInstallation;

const CalTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
`;

const StyledAntdCalender = styled(Calendar)`
  .ant-fullcalendar-column-header,
  .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.85) !important;
  }
  .ant-fullcalendar .ant-fullcalendar-tbody td {
    padding: 0;
  }
  .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
    background: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-fullcalendar-selected-day .ant-fullcalendar-value,
  .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
    background: #007dba !important;
    color: #fff !important;
  }
`;

const StyledIcon = styled(Icon)`
  color: #007dba !important;

  svg {
    display: block !important;
  }
`;

const TimeButton = styled(Button)`
  &.blue-border-btn {
    padding: 4px 0px 4px 0px;
    text-align: center;
    border-radius: 1px;
    background: transparent;
    border: 2px solid #5faed0;
    margin: 10px auto;
    width: 100%;
    height: 45px;
    display: block;
  }

  &.grey-border-btn {
    padding: 4px 0px 4px 0px;
    text-align: center;
    border-radius: 1px;
    background: transparent;
    border: 2px solid #565656;
    margin: 10px auto;
    width: 100%;
    height: 45px;
    color: #3d3d3d;
    display: block;
  }

  .anticon.anticon-check-circle {
    color: #71a517;
    padding-right: 10px;
  }

  &.btn-color-darkblue {
    background-color: #1890ff;
    border-color: #1890ff;
    font-size: 17px;
    color: #fff;
    font-weight: 400;
    padding: 7px 31px;
    border: 1px;
    height: 45px;
    width: 100%;
    text-transform: capitalize;
  }
`;

const TechArriveTimeBtn = styled(TimeButton)`
  margin-top: 12px !important;
  width: 212px !important;
`;
