import {
  ACCOUNT_CHECK,
  CHECK_DEVICE_COMPATIBILITY,
  CHECK_SERVICEABILITY,
  CREATE_SDP_TICKET,
  GET_HISTORY,
  GET_SCHEDULE_APT,
  GET_SERVICE_PROPS_BY_CODE,
  GET_VAS_PRODUCTS,
  INITIAL_ORDER_CREATE,
  REACH_ORDER_COMPLETED,
  RESET_HISTORY,
  RESET_STATE,
  RETAIL_STORE_ADDR,
  RETAIL_STORES_LOADED,
  RETAIL_STORES,
  SET_REQ_INSTALL_DATE_TIME,
  SAVE_BUSINESS_INFO,
  SAVE_CHALLENGE_QUESTION_INFO,
  SAVE_IS_REPROVISIONING_INFO,
  SAVE_LANGUAGE_PREF,
  SAVE_PASSWORD,
  SAVE_PAYMENT_INFO,
  SAVE_PLAN_INFO,
  SAVE_VAS_PLAN_INFO,
  SAVE_WIRELESS_PLAN_INFO,
  SERVICE_CHECK_FULFILLED,
  SERVICE_CHECK,
  SET_EBB_CONFIRMATION_NUM,
  SET_MOBILE_ONLY_FLOW,
  SET_OPTIONAL_MAILBOX,
  SMARTY_AUTOCOMPLETE,
  SUBMIT_ORDER,
  UPDATE_BIN_ERROR,
  UPDATE_CARD_ERROR,
  UPDATE_CUST_TC,
  UPDATE_ERROR_MSG,
  UPDATE_IS_BUSINESS_ADDRESS,
  UPDATE_IS_REACH_ORDER,
  UPDATE_ORDER,
  VALIDATE_CARD,
  GET_PROMO_BY_SOURCE_CODE,
  SET_NEW_PROMO_CODE,
  SAVE_DISCOUNTED_PRICE,
  REACH_AFFILIATE_URL,
  SET_CART_PROMO_CODE,
  CHECK_PROMO_CODE_TRACKER,
  PROMO_CODE_COUNTER,
  SET_PREFERRED_LANGUAGE,
  SET_CURRENT_STEP,
  VALIDATE_ICCIDS,
  VALIDATE_EMAIL,
  SET_ICCIDS,
  SET_SIM_CARD_FLOW,
  SET_MOBILE_PLANS,
  APPLY_PROMO_CODE,
  CLEAR_APPLY_PROMO,
  SET_ONE_TIME_PAYMENT_INFO,
  SKIP_CREATE_ACCOUNT,
  VAS_SKIP_CREATE_ACCOUNT,
  GET_BUNDLES,
  SET_BUNDLES_CART,
  VALIDATE_ADDRESS,
  SET_TERM_INFO_CONFIRM,
  SET_ORDER_ACCESS_TOKEN,
  SET_SUCCESS_MESSAGE_TERM,
} from './Types';
import { config } from '../../util/util';
import { api } from '../../util/axios';
import { getAgentId, getAffiliateID, getPromoCode } from '../../common/common';

export const initialOrderCreate = (orderDetails) => {
  orderDetails.affiliateUserId = getAgentId();
  orderDetails.partnerName = getAffiliateID();
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/initial-order-create`,
    data: orderDetails,
  });
  return {
    type: INITIAL_ORDER_CREATE,
    payload: request,
  };
};

export const updateOrder = (details, orderAccessToken) => {
  const request = api.axios({
    method: 'PUT',
    url: `${config.API_ENDPOINT}/order/${orderAccessToken}`,
    data: details,
  });
  return {
    type: UPDATE_ORDER,
    payload: request,
  };
};

export const updateOrderBinError = (details, orderId) => {
  const request = api.axios({
    method: 'PUT',
    url: `${config.API_ENDPOINT}/order/${orderId}`,
    data: details,
  });
  return {
    type: UPDATE_BIN_ERROR,
    payload: request,
  };
};

export const getOrderHistory = (orderAccessToken) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/order/${orderAccessToken}`,
  });
  return {
    type: GET_HISTORY,
    payload: request,
  };
};

export const reachAffiliateUrl = (reachPayload) => {
  reachPayload.agentName = getAgentId();
  reachPayload.affiliateName = getAffiliateID();
  reachPayload.promoCode = getPromoCode();
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/reach-elnk`,
    data: reachPayload,
  });
  return {
    type: REACH_AFFILIATE_URL,
    payload: request,
  };
};

export const savePlanInfo = (planDetails) => {
  return {
    type: SAVE_PLAN_INFO,
    payload: planDetails,
  };
};

export const saveDiscountedPrice = (price) => {
  return {
    type: SAVE_DISCOUNTED_PRICE,
    payload: price,
  };
};

export const setReqInstallDateTime = (data) => {
  return {
    type: SET_REQ_INSTALL_DATE_TIME,
    payload: data,
  };
};

export const saveVasPlanInfo = (planDetails) => {
  return {
    type: SAVE_VAS_PLAN_INFO,
    payload: planDetails,
  };
};

export const saveReprovisioning = (isRepro) => {
  return {
    type: SAVE_IS_REPROVISIONING_INFO,
    payload: isRepro,
  };
};

export const createSDPTicket = (data) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/core/support/sdp-request`,
    data,
  });

  return {
    type: CREATE_SDP_TICKET,
    payload: request,
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

export const resetHistory = () => {
  return {
    type: RESET_HISTORY,
  };
};

export const checkServiceability = (checkServiceabilityModel) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.SERVICEABILITY_BASE_URL}/prequal`,
    data: checkServiceabilityModel,
  });
  return {
    type: CHECK_SERVICEABILITY,
    payload: request,
  };
};

export const getScheduleAppt = (ScheduleApptSchema) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.SERVICEABILITY_BASE_URL}/install-status`,
    data: ScheduleApptSchema,
  });
  return {
    type: GET_SCHEDULE_APT,
    payload: request,
  };
};

export const serviceCheck = (serviceCheckModel) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/service-check`,
    data: serviceCheckModel,
  });
  return {
    type: SERVICE_CHECK,
    payload: request,
  };
};

export const saveServiceCheckId = (id) => {
  return {
    type: SERVICE_CHECK_FULFILLED,
    payload: id,
  };
};

export const accountCheck = (accountCheckModel) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/account-check`,
    data: accountCheckModel,
  });
  return {
    type: ACCOUNT_CHECK,
    payload: request,
  };
};

export const updateIsBusinessAddress = (isBusinessAddress) => {
  return {
    type: UPDATE_IS_BUSINESS_ADDRESS,
    payload: isBusinessAddress,
  };
};

export const saveBusinessInfo = (businessInfoModel) => {
  return {
    type: SAVE_BUSINESS_INFO,
    payload: businessInfoModel,
  };
};

export const saveLanguagePref = (newLanguagePref) => {
  return {
    type: SAVE_LANGUAGE_PREF,
    payload: newLanguagePref,
  };
};
export const setPreferredLanguage = (newLanguagePref) => {
  return {
    type: SET_PREFERRED_LANGUAGE,
    payload: newLanguagePref,
  };
};

export const setCurrentStep = (currentStep) => {
  return {
    type: SET_CURRENT_STEP,
    payload: currentStep,
  };
};

export const saveChgQuestionInfo = (chgQuestionModel) => {
  return {
    type: SAVE_CHALLENGE_QUESTION_INFO,
    payload: chgQuestionModel,
  };
};

export const savePaymentInfo = (paymentDetails) => {
  return {
    type: SAVE_PAYMENT_INFO,
    payload: paymentDetails,
  };
};

export const setOneTimePaymentInfo = (payload) => ({
  type: SET_ONE_TIME_PAYMENT_INFO,
  payload,
});

export const saveWirelessPlanInfo = (planDetails) => {
  return {
    type: SAVE_WIRELESS_PLAN_INFO,
    payload: planDetails,
  };
};

export const updateCustTC = (custDetail) => {
  return {
    type: UPDATE_CUST_TC,
    payload: custDetail,
  };
};

export const updateErrorMsg = (message) => {
  return {
    type: UPDATE_ERROR_MSG,
    payload: message,
  };
};

export const updateCardErrorModal = (message) => {
  return {
    type: UPDATE_CARD_ERROR,
    payload: message,
  };
};

export const savePassword = (password) => {
  return {
    type: SAVE_PASSWORD,
    payload: password,
  };
};

export const submitOrder = (submitOrderModel, isVasOrder = false) => {
  const response = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/${
      isVasOrder ? 'order-submission-vas' : 'order-submission'
    }`,
    data: submitOrderModel,
  });
  return {
    type: SUBMIT_ORDER,
    payload: response,
  };
};

export const getExpressVasProducts = (promoCode) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.SERVICEABILITY_BASE_URL}/vas-products/${promoCode}/${
      config.RESIDENTIAL_PARTNER
    }`,
  });
  return {
    type: GET_VAS_PRODUCTS,
    payload: request,
  };
};

export const validateCreditCard = (validateCardModel) => {
  const response = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/cc-validate`,
    data: validateCardModel,
  });
  return {
    type: VALIDATE_CARD,
    payload: response,
  };
};

export const setEBBConfirmationNum = (ebbConfirmationNo) => {
  return {
    type: SET_EBB_CONFIRMATION_NUM,
    payload: ebbConfirmationNo,
  };
};

export const setMobileOnlyFlow = (mobileOnly) => {
  return {
    type: SET_MOBILE_ONLY_FLOW,
    payload: mobileOnly,
  };
};

export const checkDeviceCoverage = (payload) => {
  const response = api.axios({
    method: 'POST',
    url: `${config.SERVICEABILITY_BASE_URL}/device-compatibility`,
    data: payload,
  });
  return {
    type: CHECK_DEVICE_COMPATIBILITY,
    payload: response,
  };
};
export const setRetailStoreAddr = (value) => {
  return {
    type: RETAIL_STORE_ADDR,
    payload: value,
  };
};

export const getStores = (groupId) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/retail/${groupId}/by-group-id`,
  });
  return {
    type: RETAIL_STORES,
    payload: request,
  };
};

export const smartyAutoComplete = (address, selected = '') => {
  const request = api.axios({
    method: 'GET',
    url: `${config.SMARTY_AUTOCOMPLETE}?address=${encodeURIComponent(
      address
    )}&selected=${encodeURIComponent(selected)}`,
  });
  return {
    type: SMARTY_AUTOCOMPLETE,
    payload: request,
  };
};

export const getServicePropsByCode = (servCode) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/services/${servCode}`,
  });
  return {
    type: GET_SERVICE_PROPS_BY_CODE,
    payload: request,
  };
};

export const setElnkMailboxReq = (value) => {
  return {
    type: SET_OPTIONAL_MAILBOX,
    payload: value,
  };
};
export const loadedStores = (value) => ({
  type: RETAIL_STORES_LOADED,
  paylaod: value,
});

export const getPromoBySourceCode = (sourceCode) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/promos/${sourceCode}`,
  });
  return {
    type: GET_PROMO_BY_SOURCE_CODE,
    payload: request,
  };
};

export const checkPromoCodeTracker = (partnerId, sourceCode) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/${partnerId}/${sourceCode}/promo-check`,
  });
  return {
    type: CHECK_PROMO_CODE_TRACKER,
    payload: request,
  };
};

export const setPromoCounter = (affiliateID, sourceCode) => {
  const request = api.axios({
    method: 'PUT',
    url: `${config.API_ENDPOINT}/promo/counter`,
    data: {
      partnerId: affiliateID,
      promoCode: sourceCode,
    },
  });
  return {
    type: PROMO_CODE_COUNTER,
    payload: request,
  };
};

export const setNewPromoCode = (value) => {
  return {
    type: SET_NEW_PROMO_CODE,
    payload: value,
  };
};

export const updateIsReachOrder = (isReachOrder) => {
  return {
    type: UPDATE_IS_REACH_ORDER,
    payload: isReachOrder,
  };
};

export const reachOrderCompleted = (reachOrderComplete) => {
  return {
    type: REACH_ORDER_COMPLETED,
    payload: reachOrderComplete,
  };
};

export const setCartPromoCode = (value) => {
  return {
    type: SET_CART_PROMO_CODE,
    payload: value,
  };
};

export const checkOrderStatus = async (confirmNum, sissionOrderId) => {
  try {
    const response = await api.axios({
      method: 'POST',
      url: `${config.API_ENDPOINT}/check-order-status`,
      data: {
        orderNumber: confirmNum,
        customerSessionID: sissionOrderId,
      },
    });
    const data = await response.data;
    return data.OrderStatus === 'Eligible';
  } catch (err) {
    // eslint-disable-next-line no-console
    console.dir(err);
  }
};

export const validateIccid = (iccids) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/sim-validation/search?iccid=${iccids}`,
  });
  return {
    type: VALIDATE_ICCIDS,
    payload: request,
  };
};

export const checkExistingEmail = (email) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/accounts/${encodeURIComponent(email)}/search`,
  });
  return {
    type: VALIDATE_EMAIL,
    payload: request,
  };
};

export const setIccids = (iccids = []) => ({
  type: SET_ICCIDS,
  payload: iccids,
});

export const setMobileProducts = (payload) => ({
  type: SET_MOBILE_PLANS,
  payload,
});

export const applyPromoCode = (affiliateID, sourceCode, orderAccessToken) => {
  const request = api.axios({
    method: 'PUT',
    url: `${config.API_ENDPOINT}/orders/${orderAccessToken}/applypromo`,
    data: {
      affiliateId: affiliateID,
      promoCode: sourceCode,
      partner: config.RESIDENTIAL_PARTNER,
    },
  });
  return {
    type: APPLY_PROMO_CODE,
    payload: request,
  };
};

export const clearApplyPromo = () => {
  return {
    type: CLEAR_APPLY_PROMO,
  };
};

export const skipCreateAccount = (isSkipCreateAccount) => {
  return {
    type: SKIP_CREATE_ACCOUNT,
    payload: isSkipCreateAccount,
  };
};

export const vasSkipCreateAccount = (isVasSkipCreateAccount) => {
  return {
    type: VAS_SKIP_CREATE_ACCOUNT,
    payload: isVasSkipCreateAccount,
  };
};

export const getBundles = (bundlecodes = '') => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/bundles/?bundlecodes=${bundlecodes}`,
  });
  return {
    type: GET_BUNDLES,
    payload: request,
  };
};

export const setBundlesCart = (payload) => ({
  type: SET_BUNDLES_CART,
  payload,
});

export const setSimCardFlow = (payload) => ({
  type: SET_SIM_CARD_FLOW,
  payload,
});

export const validateAddress = (address) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.SERVICEABILITY_BASE_URL}/validate-address`,
    data: address
  });
  return {
    type: VALIDATE_ADDRESS,
    payload: request,
  };
};

export const setTermInfoConfirm = (payload) => ({
  type: SET_TERM_INFO_CONFIRM,
  payload,
});

export const setOrderAccessToken = (payload) => ({
  type: SET_ORDER_ACCESS_TOKEN,
  payload,
})

export const setSuccessMessageTerm = (payload) => ({
  type: SET_SUCCESS_MESSAGE_TERM,
  payload,
});