import React from 'react';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { ValidationMessages } from '../../common/validationMessages';
import { alphaWithSpaceRegex, onlyNumberRegex } from '../../util/regex';
import { isUndefinedNullOrEmpty,checkTollFreeNumber } from '../../common/common';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isMobile } from 'react-device-detect';
class BusinessInfo extends React.Component {
  constructor(props) {
    super(props);
    const { businessInfo } = this.props.OrderState;
    this.state = {
      businessName: isUndefinedNullOrEmpty(businessInfo.businessName) ? '' : businessInfo.businessName,
      businessPhone: isUndefinedNullOrEmpty(businessInfo.businessPhone) ? '' : `+1${businessInfo.businessPhone}`,
      error: {},
      noOfEmployees: isUndefinedNullOrEmpty(businessInfo.noOfEmployees) ? '' : businessInfo.noOfEmployees,
      noOfLocations: isUndefinedNullOrEmpty(businessInfo.noOfLocations) ? '' : businessInfo.noOfLocations,
      isMob: isMobile
    };
  }

  handleChange = (event) => {
    let { value, name } = event.target;
    if (name === 'businessName') {
      if (!alphaWithSpaceRegex.test(value))
        return;
    }
    if (name === 'noOfLocations' || name === 'noOfEmployees') {
      value = Number(value) || 0;
    }
    this.setState({
      [name]: String(value)
    });
  }

  validateInputFields = (field) => {
    const { businessName, businessPhone, noOfEmployees, noOfLocations,isMob } = this.state;
    let error = this.state.error;
    //RESET STATE
    delete error[field];

    //REQUIRED FIELD VALIDATIONS
    if (this.state[field] === '') {
      error = {
        ...error,
        [field]: isMob ? ValidationMessages.mobRequired : ValidationMessages.required,
      };
    }

    if ((field === 'noOfEmployees' && noOfEmployees === 0) ||
      (field === 'noOfLocations' && noOfLocations === 0)
    ) {
      error = {
        ...error,
        [field]: ValidationMessages.businessRequired,
      };
    }

    //VALIDATE PHONE NUMBER
    if (field === 'businessPhone' && businessPhone.length !== 0) {
      if (businessPhone.length < 10) {
        error = {
          ...error,
          businessPhone: ValidationMessages.min10Length
        };
      }
      else if (!isValidPhoneNumber(businessPhone)) {
        error = {
          ...error,
          businessPhone: ValidationMessages.invalidPhoneNumber
        };
      }
      else if (checkTollFreeNumber(businessPhone.toString())) {
        error = {
          ...error,
          businessPhone: 'Toll-Free numbers are not accepted'
        }
      }
    }
    //VALIDATE BUSINESS NAME
    if (field === 'businessName' && businessName !== '') {
      if (businessName.length < 2) {
        error = {
          ...error,
          businessName: ValidationMessages.min2Length
        };
      }
      if (onlyNumberRegex.test(businessName)) {
        error = {
          ...error,
          businessName: ValidationMessages.notOnlyNumerics
        };
      }
    }

    this.setState({ error }, () => {
      this.props.setBusinessState(this.state);
    });
  }

  handlePhoneChange = (value) => {
    this.setState({ businessPhone: value === undefined ? '' : value });
  }

  handleKeyUp = () => {
    const phoneNumber = document.getElementById('businessPhone') && document.getElementById('businessPhone').value;
    if (!isUndefinedNullOrEmpty(phoneNumber)) {
      if (phoneNumber.charAt(0) === '+' || phoneNumber.length === 1 && (phoneNumber === '0' || phoneNumber === '1')) {
        this.setState({ businessPhone: null });
      }
      if (phoneNumber.charAt(0) === '+' || phoneNumber.charAt(0) === '1' || phoneNumber.charAt(0) === '0') {
        this.setState({ businessPhone: null });
      }
    }
    return;
  }

  render() {
    const { businessName, businessPhone, noOfLocations, noOfEmployees, error } = this.state;
    return (
      <>
        <h3 className="serviceTitle text-dark">Business Information</h3>

        <div className="form-row">
          <div className="form-group  col-md-12">
            <input
              className="form-control formInputBox"
              id="businessName"
              autoComplete="off"
              autoFocus
              maxLength="50"
              onBlur={(e) => this.validateInputFields(e.target.name)}
              onChange={this.handleChange}
              placeholder="Business Name"
              value={businessName} name="businessName"
            >
            </input>
            {
              error.businessName &&
              <label className="submitErr">{error.businessName}</label>
            }
          </div>
        </div>

        <div className="form-row">
          <div className="form-group  col-md-12">
            <Input
              className="form-control formInputBox"
              country="US"
              autoComplete="off"
              maxLength={14}
              name="businessPhone"
              id="businessPhone"
              onKeyUp={this.handleKeyUp}
              onBlur={(e) => this.validateInputFields(e.target.name)}
              onChange={this.handlePhoneChange}
              placeholder="Business Phone"
              value={businessPhone}
            />
            {
              error.businessPhone &&
              <label className="submitErr">{error.businessPhone}</label>
            }
          </div>
        </div>

        <div className="form-row">
          <div className="form-group  col-md-6 col-sm-6 col-xs-12 padding-0">
            <input
              className="form-control formInputBox"
              id="noOfLocations"
              autoComplete="off"
              maxLength="4"
              min="1"
              name="noOfLocations"
              onBlur={(e) => this.validateInputFields(e.target.name)}
              onChange={this.handleChange}
              placeholder="Number Of Locations"
              type="text"
              value={noOfLocations}
            >

            </input>
            {
              error.noOfLocations &&
              <label className="submitErr">{error.noOfLocations}</label>
            }
          </div>
          <div className="form-group  col-md-6 col-sm-6 col-xs-12">

            <input
              className="form-control formInputBox"
              id="noOfEmployees"
              autoComplete="off"
              maxLength="5"
              min="1"
              name="noOfEmployees"
              onBlur={(e) => this.validateInputFields(e.target.name)}
              onChange={this.handleChange}
              placeholder="Number Of Employees"
              type="text"
              value={noOfEmployees}
            >

            </input>
            {
              error.noOfEmployees &&
              <label className="submitErr">{error.noOfEmployees}</label>
            }
          </div>
        </div>

      </>
    )
  }
}

export default BusinessInfo;
