import React from 'react';

const ApiError = (props) => {
  const { errorMessage } = props.OrderState;
  return (
    <div className="submitErr">
      {
        typeof(errorMessage) === String ? errorMessage : 'Something went wrong, please try again after sometime'
      }
    </div >
  )
}
export default ApiError
