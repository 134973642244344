import React from 'react';
import { RoutesContainer } from '../src/containers/login';

class App extends React.Component {

  render() {
    return (
      <RoutesContainer />
    )
  }
}

export default App;
