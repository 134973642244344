import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { setPreferredLanguage, resetState, saveLanguagePref } from '../../redux/actions/OrderAction';
import TopNavBar from './TopNavBar';
import store from '../../store';
import * as OrderActions from '../../redux/actions/OrderAction';
import { getAgentId, clearSessionOrderId, clearReachUrl, isUndefinedNullOrEmpty } from '../../common/common';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';
import { bindActionCreators } from 'redux';

const NavBar = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { agentName, affiliateID } = props.LoginState;
  const { retailStoresAddr, loading } = props.OrderState;
  affiliateID = isUndefinedNullOrEmpty(affiliateID) ? '' : affiliateID.toLowerCase();
  const [loggedIn, setLoginStatus] = useState('');
  const [hideAffSupport] = useState(['earthlink', 'ignition'].includes(affiliateID));
  const top = window.screen.width < 992 ? 'top50' : 'top0';
  const [navBarTop, setNavBarTop] = useState([top]);
  const [storeNum, setStoreNum] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.LoginState) {
      setLoginStatus(getAgentId());
    }
  }, [props.LoginState]);

  useEffect(() => {
    if (retailStoresAddr.includes('-')) {
      setStoreNum(retailStoresAddr.split('-')[0]);
    } else if (retailStoresAddr !== '') {
      const capitalizeAddress = retailStoresAddr[0].toUpperCase() + retailStoresAddr.substring(1);
      setStoreNum(capitalizeAddress);
    } else {
      setStoreNum(retailStoresAddr);
    }
  }, [retailStoresAddr]);

  const handleLogoClick = () => {
    clearSessionOrderId();
    clearReachUrl();
    store.dispatch(resetState());
  }
  const { isPreferredLangSpanish, currentStep, languagePref } = useSelector(state => ({
    languagePref: state.OrderState.languagePref,
    isPreferredLangSpanish: state.OrderState.isPreferredLangSpanish,
    currentStep: state.OrderState.currentStep
  }));

  useEffect(() => {
    const handleScroll = () => {
      var getNavBarTopValue = '';
      if (window.pageYOffset > 1) {
        getNavBarTopValue = 'top0';
      }
      if (window.pageYOffset === 0 && window.screen.width < 992) {
        getNavBarTopValue = 'top50';
      }
      setNavBarTop(getNavBarTopValue);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onChange = () => {
    dispatch(saveLanguagePref(!languagePref))
    dispatch(setPreferredLanguage(!isPreferredLangSpanish))
  }

  const resetStoreAddr = () => {
    props.OrderActions.resetState();
    props.OrderActions.resetHistory();
    props.OrderActions.setRetailStoreAddr('');
    history.push('/');
  };

  const isModalFound = Boolean(document.querySelector('#cartSummaryDescription.modal'));

  return (
    <div>
      <TopNavBar />
      <nav className={`navbar navbar-expand-lg navbar-light bg-light navContainer fixed-top p-0 ${navBarTop}`} >
        <div className="container h-65 mwd-100">
          <a className="navbar-brand" href="#" onClick={handleLogoClick} rel="noopener noreferrer">
            <img src="/images/earthlink-logo.png" alt="logo" />
          </a>
          <div className="affiliate-id mob-show">
            {
              loggedIn ?
                <div className="navbar-text affId">
                  <div className="dropdown show">
                    <a className="dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => { return false }}>
                      <span className="icon-dv"><img src="/images/user.png" alt="affiliate" width="38" height="22" className="mr-2" /></span>
                      <span className="profile-dv">
                        <i className="fas fa-caret-down"></i>
                      </span>
                    </a>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <span className="text-left text-white dropdown-item" >
                        {
                          agentName
                        }
                      </span>
                      <a className="dropdown-item" href="/" onClick={() => { sessionStorage.clear(); localStorage.clear(); }}>Logout</a>
                    </div>
                  </div>
                  {!_.isEmpty(props.OrderState.planInfo) && isModalFound &&
                    <button className="cart-button"
                      data-toggle="modal"
                      data-target="#cartSummaryDescription">
                      <i className="fas fa-cart-arrow-down" style={{ color: '#007dba' }}></i>
                    </button>
                  }
                </div> : ''
            }
          </div>
          <div className="affiliate-id mob-hide">
            {
              loggedIn ?
                <div className="navbar-text affId">
                  <div className="dropdown show">
                    <a className="dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => { return false }}>
                      <span className="icon-dv"><img src="/images/user.png" alt="affiliate" width="38" height="22" className="mr-2" /></span>
                      {/* <span className="text-left text-white  dropdown-item" > */}
                      {
                        props.LoginState.agentName
                      }
                      {/* </span> */}
                      <span className="profile-dv-mob">
                        <i className="fas fa-caret-down"></i>
                      </span>
                    </a>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      {/* <span className="text-left text-white  dropdown-item" >
                        {
                          props.LoginState.agentName
                        }
                      </span> */}
                      <a className="dropdown-item" href="/" onClick={() => { sessionStorage.clear(); localStorage.clear(); }}>Logout</a>
                    </div>
                  </div>

                </div> : ''
            }
          </div>
          {/* <button className="navbar-toggler navbar-toggler-right-top collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* {
            loggedIn ? <span className="navbar-text affId">{loggedIn}</span> : ''
          }
            */}
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <div className="navLinks h-65">
              <ul className="navbar-nav h-65">
                {storeNum !== '' ? (
                  <>
                    <h6>{storeNum}</h6>
                    <button className="navbarEditbtn" disabled={loading} >

                      <i
                        data-dismiss="modal"
                        className="changeServAddress fas fa-edit ml-2"
                        onClick={resetStoreAddr}
                        style={{ color: '#007DBA' }}
                      />
                    </button>
                  </>
                ) : null}

                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>Residential</span>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item" href="/">
                      <span>Internet</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/">
                      <span>Security &amp; Tools</span>
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>Business</span>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item" href="/">
                      <span>Internet</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/">
                      <span>Security &amp; Tools</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/">
                      <span>Digital Marketing</span>
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>My EarthLink</span>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item" href="/">
                      <span>My EarthLink</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/">
                      <span>My Account</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/">
                      <span>Support</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/">
                      <span>Blog</span>
                    </a>
                  </div>
                </li> */}

                {/* <li className="nav-item">
                  <a className="nav-link" href="https://support.earthlink.net/">
                    <span>Support</span>
                  </a>
                </li> */}
                {
                  hideAffSupport ?
                    null
                    :
                    <>
                      {/* <li className="nav-item free-consult-nav">
                        <div>Need Help? <br />Call Affiliate Support</div>
                        <button type="button" className="btn regBtn-grid"><a href="tel:833-957-1480">833-957-1480</a></button>
                      </li> */}
                      <li className="nav-item mob-show">
                        <a className="nav-link" href="/">
                          <span>Live Chat</span>
                        </a>
                      </li>
                    </>
                }
                {currentStep === 7 && sessionStorage.getItem('isPreferredLangSpanish') === 'true' && <Switch
                  onChange={onChange} checkedChildren="Spanish" unCheckedChildren="English" checked={languagePref} />}
              </ul>
            </div>
          </div>

          {/* <div className="navbar-text navbar-text_phone ml-2">
            <i className="fas fa-mobile-alt mr-2" />
            <a href="tel:866-383-3080">866-383-3080</a>
          </div> */}
        </div>
      </nav>
    </div>
  );
}

//MAP STATE TO PROPS
const mapStateToProps = state => {
  return {
    LoginState: state.LoginState,
    OrderState: state.OrderState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    OrderActions: bindActionCreators(OrderActions, dispatch),
  }
}

//CONNNECT COMPONENTS TO REDUX
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);



