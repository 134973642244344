import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import loadable from '@loadable/component';

import * as OrderActions from '../redux/actions/OrderAction';
import * as LoginActions from '../redux/actions/LoginActions';
import AddressSuggestionSelect from '../components/common/AddressSuggestionSelect';
import EnterInfo from '../components/order/EnterInfo';

// LOADABLE FOR LAZY LAODING

const orderStepper = loadable(() => import(/* webpackChunkName: "OrderStepper" */  '../components/order/OrderStepper'), {
    fallback: <div>Loading..</div>
});

const orderCheckout = loadable(() => import(/* webpackChunkName: "OrderCheckout" */  '../components/order/Checkout'), {
    fallback: <div>Loading..</div>
});

const NavBar = loadable(() => import(/* webpackChunkName: "NavBar" */'../components/topbar/NavBar'), {
    fallback: <div>Loading..</div>
});

const NonServiceable = loadable(() => import(/* webpackChunkName: "NonServiceable" */'../components/order/NonServiceable'), {
    fallback: <div>Loading..</div>
});

const BusinessInfo = loadable(() => import(/* webpackChunkName: "ApiError" */  '../components/order/BusinessInfo'), {
    fallback: <div>Loading..</div>
});

const CreateAccount = loadable(() => import(/* webpackChunkName: "Step4_CreateAccount" */  '../components/order/CreateAccount'), {
    fallback: <div>Loading..</div>
});

// const ChallengeQuestion = loadable(() => import(/* webpackChunkName: "Step5_ChallengeQuestion" */  '../components/order/ChallengeQuestion'), {
//     fallback: <div>Loading..</div>
// });

const Payment = loadable(() => import(/* webpackChunkName: "Step6_Payment" */  '../components/order/Payment'), {
    fallback: <div>Loading..</div>
});

const OrderConfirmation = loadable(() => import(/* webpackChunkName: "Step8_OrderConfirmation" */  '../components/order/OrderConfirmation'), {
    fallback: <div>Loading..</div>
});

const StoreCheck = loadable(() => import(/* webpackChunkName: "StoreCheck" */  '../components/order/StoreCheck'), {
    fallback: <div>Loading..</div>
});

const Serviceability = loadable(() => import(/* webpackChunkName: "Step1_ServiceabilityCheck" */  '../components/order/ServiceabilityCheck'), {
    fallback: <div>Loading..</div>
});

const VasPage = loadable(() => import(/* webpackChunkName: "VasPage" */ '../components/order/VasPage'), {
    fallback: <div>Loading..</div>
});

const VasProducts = loadable(() => import(/* webpackChunkName: "VasPage" */ '../components/vas/VasProducts'), {
    fallback: <div>Loading..</div>
});

const ReachOrder = loadable(() => import(/* webpackChunkName: "ReachOrder" */ '../components/reach/ReachOrder'), {
    fallback: <div>Loading..</div>
});

const VasOrder = loadable(() => import(/* webpackChunkName: "VasPage" */ '../components/vas/VasOrder'), {
    fallback: <div>Loading..</div>
});

const VasOrderStepper = loadable(() => import(/* webpackChunkName: "VasPage" */ '../components/vas/VasOrderStepper'), {
    fallback: <div>Loading..</div>
});

const Product = loadable(() => import(/* webpackChunkName: "Product" */  '../components/order/Product'), {
    fallback: <div>Loading..</div>
});

const OrderThanks = loadable(() => import(/* webpackChunkName: "OrderThanks" */  '../components/order/OrderThanksPage'), {
    fallback: <div>Loading..</div>
});

const AddressSuggestions = loadable(() => import(/* webpackChunkName: "AddressSuggestions" */  '../components/order/AddressSuggestions'), {
    fallback: <div>Loading..</div>
});

const ApiError = loadable(() => import(/* webpackChunkName: "ApiError" */  '../components/common/ApiError'), {
    fallback: <div>Loading..</div>
});

const SimNumber = loadable(() => import(/* webpackChunkName: "ApiError" */  '../components/order/SimCards'), {
    fallback: <div>Loading..</div>
});

//MAP STATE TO PROPS
const mapStateToProps = state => {
    return {
        LoginState: state.LoginState,
        OrderState: state.OrderState,
    }
}

//MAP DISPATCH TO PROPS
const mapDispatchToProps = dispatch => {
    return {
        OrderActions: bindActionCreators(OrderActions, dispatch),
        LoginActions: bindActionCreators(LoginActions, dispatch),
    }
}

//CONNNECT COMPONENTS TO REDUX
const containerCreator = connect(mapStateToProps, mapDispatchToProps);


// MAIN CONTAINERS
// export const ChallengeQuestionContainer = containerCreator(ChallengeQuestion)
export const AddressSuggestionSelectContainer = containerCreator(AddressSuggestionSelect);
export const AddressSuggestionsContainer = containerCreator(AddressSuggestions);
export const ApiErrorContainer = containerCreator(ApiError);
export const BusinessInfoContainer = containerCreator(BusinessInfo);
export const CreateAccountContainer = containerCreator(CreateAccount);
export const EnterInfoContainer = containerCreator(EnterInfo);
export const NavBarContainer = containerCreator(NavBar);
export const NonServiceableContainer = containerCreator(NonServiceable);
export const OrderConfirmationContainer = containerCreator(OrderConfirmation);
export const OrderStepperContainer = containerCreator(orderStepper);
export const OrderThanksContainer = containerCreator(OrderThanks);
export const PaymentContainer = containerCreator(Payment);
export const ProductContainer = containerCreator(Product);
export const StoreCheckContainer = containerCreator(StoreCheck);
export const ServiceabilityContainer = containerCreator(Serviceability);
export const VasContainer = containerCreator(VasPage);
export const VasOrderContainer = containerCreator(VasOrder);
export const VasOrderStepperContainer = containerCreator(VasOrderStepper);
export const VasProductsContainer = containerCreator(VasProducts);
export const ReachContainer = containerCreator(ReachOrder);
export const CheckoutContainer = containerCreator(orderCheckout);
export const SimNumberContainer = containerCreator(SimNumber);
