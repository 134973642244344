import React from 'react';

const PageNotFound = () => {

  return (
    <div className="">
      <div className="notfound-sec">
        <div className="heading-text">
          <h1 className="bigtext">
            404
        </h1>
        </div>
        <h2>Oops! Something went wrong…</h2>
        <p>Page not found. Please continue to our <a href="https://www.earthlink.net/">home page</a></p>
        <hr></hr>
        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#errorModal">
          500 Error
        </button>
      </div>



      <div className="modal" id="errorModal" tabIndex="-1" role="dialog" >
        <div className="modal-dialog  modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="internal-error">
                <img src="/images/error-500.png" className="img-fluid" alt="500image"></img>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>




  );
}

export default PageNotFound;

