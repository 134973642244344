import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as LoginActions from '../redux/actions/LoginActions'
import * as OrderAction from '../redux/actions/OrderAction'
import Routes from '../routes/Routes'
import loadable from '@loadable/component'

// LOADABLE FOR LAZY LAODING

const AffiliateLogin = loadable(() => import(/* webpackChunkName: "Affiliate login" */  '../components/login/AffiliateLogin'), {
    fallback: <div>Loading.....</div>
});

//MAP STATE TO PROPS
const mapStateToProps = state => {
    return {
        LoginState: state.LoginState
    }
}

//MAP DISPATCH TO PROPS
const mapDispatchToProps = dispatch => {
    return {
        LoginActions: bindActionCreators(LoginActions, dispatch),
        OrderActions: bindActionCreators(OrderAction, dispatch),
    }
}

//CONNECT COMPONENTS TO REDUX
const containerCreator = connect(mapStateToProps, mapDispatchToProps)


// MAIN CONTAINERS
export const LoginContainer = containerCreator(AffiliateLogin)
export const RoutesContainer = containerCreator(Routes)










