import { createStore, applyMiddleware, compose } from 'redux'
import reducer from './redux/reducers'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(
    // other store enhancers if any
    thunk, promise
  )
)

export default createStore(reducer, enhancer)