import React from 'react';
import {
  clearSessionOrderId,
  setPrequalErrorCount,
} from '../../common/common';


const NonServiceable = (props) => {

const cancelOrderAndReset = () => {
  props.LoginActions.setPrequalErrorCount(0); // when click on back home reset redux to 0
  setPrequalErrorCount(0); // when click on back home reset session storage to 0
  clearSessionOrderId();
  props.OrderActions.resetState();
  props.history.push('/');
}

    return (
        <div className="content partnerproduct">
          <div className="text-left">
            <div className="container">
              <div className="heroWrap text-center">
                <div className="serviceTitle">
                  <h2 className="serviceTitleText">Our findings show EarthLink high speed internet is not yet available at this specific address. <br></br><br></br>
                  Please contact Affiliate Support for additional options. </h2>
                  </div>
                  <br></br>
                  <button
                    type="button"
                    name="backToHome"
                    className="btn-color-darkblue backToHome"
                    onClick={cancelOrderAndReset}
                  >BACK TO HOME</button>
              </div>
            </div>
          </div>
        </div>
    )
}

export default NonServiceable;
