import moment from 'moment-timezone';

import { getSegregatedAddress, getCallLogId, getAgentId, getPromoCode, getBusinessPromoCode, getAffiliateID, getProductsCount } from '../common/common';
import { SecQueOptions } from '../data/securityQuestions';
import { config, OrderStatus } from '../util/util';

export const getPersonalInfoModel = (
  fName,
  lName,
  callLogId,
  email,
  num,
  // billingAdd1,
  // billingAdd2,
  // billingCity,
  // billingState,
  // billingZip,
  // serviceAdd1,
  // serviceAdd2,
  // serviceCity,
  //serviceReference,
  // serviceState,
  // serviceZip,
  // addressIsSame,
  cellPhoneTermsAgreed,
  serviceCheckId,
) => {

  let orderInfoModel = {};
  //PERSONAL DETAILS
  orderInfoModel.firstName = fName;
  orderInfoModel.lastName = lName;
  orderInfoModel.email = email;
  orderInfoModel.phoneNum = num;
  //SERVICE ADDRESS DETAILS
  // orderInfoModel.serviceAddress = getAggregatedAddress({
  //   'address1': serviceAdd1,
  //   'address2': serviceAdd2,
  //   'city': serviceCity,
  //   'state': serviceState,
  //   'zipCode': serviceZip
  // });
  // orderInfoModel.serviceAdd1 = serviceAdd1;
  // orderInfoModel.serviceAdd2 = serviceAdd2;
  // orderInfoModel.serviceCity = serviceCity;
  // orderInfoModel.serviceState = serviceState;
  // orderInfoModel.serviceZip = serviceZip;
  //BILLING ADDRESS DETAILS
  // if (addressIsSame && !isVasOrder) {
  //   orderInfoModel.billingAddress = getAggregatedAddress({
  //     'address1': serviceAdd1,
  //     'address2': serviceAdd2,
  //     'city': serviceCity,
  //     'state': serviceState,
  //     'zipCode': serviceZip
  //   });
  //   orderInfoModel.billingAdd1 = serviceAdd1;
  //   orderInfoModel.billingAdd2 = serviceAdd2;
  //   orderInfoModel.billingCity = serviceCity;
  //   orderInfoModel.billingState = serviceState;
  //   orderInfoModel.billingZip = serviceZip;
  // } else {
  //   orderInfoModel.billingAddress = getAggregatedAddress({
  //     'address1': billingAdd1,
  //     'address2': billingAdd2,
  //     'city': billingCity,
  //     'state': billingState,
  //     'zipCode': billingZip
  //   })
  //   orderInfoModel.billingAdd1 = billingAdd1;
  //   orderInfoModel.billingAdd2 = billingAdd2;
  //   orderInfoModel.billingCity = billingCity;
  //   orderInfoModel.billingState = billingState;
  //   orderInfoModel.billingZip = billingZip;
  // }
  orderInfoModel.orderStatus = OrderStatus.basicInfo;
  orderInfoModel.callLogId = callLogId;
  // orderInfoModel.serviceReference = serviceReference;
  orderInfoModel.cellPhoneTermsAgreed = cellPhoneTermsAgreed;
  orderInfoModel.serviceCheckId = serviceCheckId;
  return orderInfoModel;
}

export const getLanguagePref = (languagePref) => {
  return languagePref ? 'Spanish' : '';
}

export const billingPeriod = (isPreferredLangSpanish, period) => {
  if (period === 'daily') {
    return ' / day';
  }
  if (period === 'monthly') {
    return isPreferredLangSpanish ? ' / mes' : ' / mo';
  }
  if (period === 'daily') {
    return ' / yr';
  }
}

//no longer used - first q and a in getCreateAccountInfoModel
export const getChgQuestionModel = (
  firstSecurityQuestion,
  firstSecurityAnswer,
  secondSecurityQuestion,
  secondSecurityAnswer,
  thirdSecurityQuestion,
  thirdSecurityAnswer
) => {
  let chgQuestionModel = {}
  chgQuestionModel.firstSecurityQuestion = SecQueOptions[firstSecurityQuestion];
  chgQuestionModel.firstSecurityAnswer = firstSecurityAnswer;
  chgQuestionModel.secondSecurityQuestion = SecQueOptions[secondSecurityQuestion];
  chgQuestionModel.secondSecurityAnswer = secondSecurityAnswer;
  chgQuestionModel.thirdSecurityQuestion = SecQueOptions[thirdSecurityQuestion];
  chgQuestionModel.thirdSecurityAnswer = thirdSecurityAnswer;
  chgQuestionModel.orderStatus = OrderStatus.secQaInfo;
  return chgQuestionModel;
}

export const getServiceAddressModel = (
  serviceAddress,
  serviceAdd1,
  serviceAdd2,
  serviceCity,
  serviceState,
  serviceZip
) => {
  let serviceAddressModel = {};
  serviceAddressModel.serviceAddress = serviceAddress;
  serviceAddressModel.serviceAdd1 = serviceAdd1;
  serviceAddressModel.serviceAdd2 = serviceAdd2;
  serviceAddressModel.serviceCity = serviceCity;
  serviceAddressModel.serviceState = serviceState;
  serviceAddressModel.serviceZip = serviceZip;
  return serviceAddressModel;
}

export const getCheckServiceableModel = (address, suggestedAddress, affiliateID, promoCode) => {
  let checkServiceabilityModel = {};
  if (suggestedAddress) {
    checkServiceabilityModel = {
      address,
      affiliateID,
      promoCode,
      partner: config.RESIDENTIAL_PARTNER,
      suggestedAddress,
      keyFlags: 'extended_output'
    }
  } else {
    checkServiceabilityModel = {
      address,
      affiliateID,
      promoCode,
      partner: config.RESIDENTIAL_PARTNER,
      keyFlags: 'extended_output'
    }
  }
  return checkServiceabilityModel;
}

export const getServiceCheckModel = (
  address,
  address1,
  address2,
  city,
  state,
  zip,
  callLogId,
  id,
  agentId,
  productList,
  nbcScore,
) => {
  let serviceCheckModel = {
    address,
    address1,
    address2,
    city,
    state,
    zip,
    callLogId,
    orderId: id,
    agentId,
    productList,
    nbcScore,
  }
  return serviceCheckModel;
}

export const getAccountCheckModel = (
  firstName,
  lastName,
  accountEmail
) => {
  let accountCheckModel = {
    firstName,
    lastName,
    accountEmail: accountEmail.split('@')[0],
    domain: accountEmail.split('@')[1] || 'earthlink.net',
  }
  return accountCheckModel;
}

export const getOrderSubmissionModel = ({
  accountEmail,
  agentReadTC,
  answer,
  billingAddress,
  creditCardCSV,
  creditCardExp,
  creditCardNum,
  creditCardCSV2,
  creditCardExp2,
  creditCardNum2,
  customerAcceptedTC,
  ebbConfirmationNo,
  email,
  firstName,
  isVasOrder,
  isBusinessAddress,
  lastName,
  orderId,
  password,
  phoneNum,
  question,
  storeId,
  serviceAddress,
  serviceReference,
  languagePref,
  isElnkMailboxReq,
  installWindow,
  installPhone,
  manualPromoCode,
  bundleCode,
  iccid = '',
}) => {
  const billingAddr = getSegregatedAddress(billingAddress);
  const createPrimary = isElnkMailboxReq && accountEmail ? { accountEmail: accountEmail.split('@')[0] || '' } : { createPrimary: 'no' };
  const installPhoneInfo = installPhone ? { installPhone } : {};
  const ioPromoCode = bundleCode ? { ioPromoCode: bundleCode } : {};
  const altContactEmail = email;
  let orderSubmissionModel = {};

  if (isVasOrder) {
    const promoCode = ( isBusinessAddress && getBusinessPromoCode() ) ? getBusinessPromoCode() : config.VAS_PROMO_CODE;
    orderSubmissionModel = {
      affiliateId: getAffiliateID(),
      broadbandOptionalFields: {
        salesAgentId: getAgentId(),
        // All the servRefs should be comma seprated and in order - HL, VAS, MOBILE/Wireless otherwise order-submit will not work
        serviceReference,
        storeId,
      },
      billingAddress: {
        billingAddress1: billingAddr.address1,
        billingAddress2: billingAddr.address2,
        billingCity: billingAddr.city,
        billingState: billingAddr.state,
        billingZip: billingAddr.zip
      },
      regInfo: {
        ...createPrimary,
        agentReadTC,
        creditCardCSV,
        creditCardExp,
        creditCardNum,
        customerAcceptedTC,
        ebbConfirmationNo,
        firstName,
        firstSecurityAnswer: answer,
        firstSecurityQuestion: question,
        languagePref: languagePref,
        lastName,
        password,
        phoneNum,
      },
      email,
      orderId,
      promoCode,
    };
  } else {
    const serviceAddr = getSegregatedAddress(serviceAddress);
    orderSubmissionModel = {
      altContactEmail,
      affiliateId: getAffiliateID(),
      callLogId: getCallLogId(),
      broadbandOptionalFields: {
        salesAgentId: getAgentId(),
        serviceReference,
        storeId,
      },
      billingAddress: {
        billingAddress1: billingAddr.address1,
        billingAddress2: billingAddr.address2,
        billingCity: billingAddr.city,
        billingState: billingAddr.state,
        billingZip: billingAddr.zip,
      },
      regInfo: {
        ...createPrimary,
        agentReadTC,
        creditCardCSV,
        creditCardExp,
        creditCardNum,
        creditCardCSV2,
        creditCardExp2,
        creditCardNum2,
        customerAcceptedTC,
        ebbConfirmationNo,
        firstName,
        firstSecurityAnswer: answer,
        firstSecurityQuestion: question,
        languagePref: languagePref,
        lastName,
        password,
        phoneNum,
        serviceAddress1: serviceAddr.address1,
        serviceAddress2: serviceAddr.address2,
        serviceCity: serviceAddr.city,
        serviceState: serviceAddr.state,
        serviceZip: serviceAddr.zip,
        ...ioPromoCode,
      },
      iccid,
      installWindow: installWindow && installWindow.length > 0 ? installWindow : '',
      ...installPhoneInfo,
      email,
      orderId,
      promoCode: manualPromoCode || getPromoCode(),
    }
  }
  return orderSubmissionModel;
}

export const getCreateAccountInfoModel = (
  email,
  isElnkMailboxReq
) => {
  let createAccountInfoModel = {
    accountEmail: email.indexOf('@') === -1 ? `${email}@earthlink.net` : email,
    orderStatus: OrderStatus.acctEmail
  }
  if (!isElnkMailboxReq) {
    createAccountInfoModel.email = email;
  }
  return createAccountInfoModel;
}

export const getPaymentInfoModel = (
  agentReadTC,
  customerAcceptedTC,
  maskedCcn,
  encryptedCcn,
  encryptedCvc,
  cardHolderName,
  expirationDate,
  integrityCheck,
) => {
  let paymentInfoModel = {
    agentReadTC,
    customerAcceptedTC,
    maskedCcn,
    encryptedCcn,
    encryptedCvc,
    cardHolderName,
    expirationDate,
    integrityCheck,
  }
  return paymentInfoModel;
}

export const getCreateSDPModel = (orderDetails) => {
  const {
    accountEmail,
    billingAddress,
    confirmationNo,
    customerNumber,
    firstName,
    lastName,
    phoneNum,
    reqInstallDate,
    serviceAddress,
    serviceReference,
  } = orderDetails;

  return {
    subject: `Reprocessing Fee - ${customerNumber} - ${moment().tz('America/New_York').format('MM/DD/YYYY')}`,
    description: ` 
        Customer Name: ${firstName.charAt(0).toUpperCase() + firstName.substr(1).toLowerCase()} ${lastName.charAt(0).toUpperCase() + lastName.substr(1).toLowerCase()}<br/>
        Service Address: ${serviceAddress.replace(/, /g, ', ')}<br/>
        Billing Address: ${billingAddress.replace(/, /g, ', ')}<br/>
        Phone Number: ${phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}<br/>
        Installation Date: ${moment(reqInstallDate).format('MM/DD/YYYY')}<br/>
        Confirmation Number: ${confirmationNo}<br/>
        Customer Number: ${customerNumber}<br/>
        Account Email: ${accountEmail.toLowerCase() + '@earthlink.net'}<br/>
        services: ${serviceReference.replace(/,/g, ', ')}<br/>`
  }
}

export const getServiceTerm = (serviceTerm, selectedData) => {
  if (serviceTerm?.length > 0) {
    return serviceTerm?.find(service => service.servServRef === selectedData?.servRef)?.servX4Y?.find(item => item?.servType?.toLowerCase() === 'service');
  }
  return {};
};

export const getSpeedInfo = (kbps) => {
  if (!kbps) {
    return '';
  }
  const speed = Number(kbps);

  if (speed >= 1000000) {
    return speed / 1000000 + ' Gbps';
  } else if (speed >= 1000) {
    return speed / 1000 + ' Mbps';
  }
  return speed + ' Kbps';
};

export const isProductInBundleCart = (servRef, orderState) => {
  const { bundlesCart, wirelessCartInfo, vasPlanInfo } = orderState;

  const { wirelessPlanInfo = [] } = wirelessCartInfo;

  if (bundlesCart.selectedIpb?.servRef === servRef) {
    return true;
  }

  const mobileBundleItems = bundlesCart.mobileProducts?.filter((product) => product.servRef === servRef) || [];
  const mobileCartItems = wirelessPlanInfo?.filter((product) => product.servRef === servRef) || [];

  if (mobileBundleItems.length) {
    return getProductsCount(mobileBundleItems) >= getProductsCount(mobileCartItems);
  }

  const { vasPlan = [] } = vasPlanInfo;

  const vasBundleItems = bundlesCart.vasProducts?.filter((product) => product.servRef === servRef) || [];
  const vasCartItems = vasPlan?.filter((product) => product.servRef === servRef) || [];

  if (vasBundleItems.length) {
    return vasBundleItems.length >= vasCartItems.length;
  }
};

export const filterAvailableBundles = (bundle, serviceabilityCheck) => {
  const { plans = [], vasProducts = [], mobileProducts = [] } = serviceabilityCheck || {};

  return [1, 2, 3, 4].every((key) => {
    return [...plans, ...vasProducts, ...mobileProducts].some((plan) => {
      return !bundle[`servLevel${key}`] || bundle[`servLevel${key}`] === '*' || bundle[`servLevel${key}`].toLowerCase() === plan.servLevel?.toLowerCase();
    });
  });
}
