//AFFILIATE LOGIN
export const LOGIN = 'LOGIN';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGOUT = 'LOGOUT';
export const UPDATE_TOKENS = 'UPDATE_TOKENS';
export const SET_LOGIN_EMAILS = 'SET_LOGIN_EMAILS';
export const SET_SUBMIT_ERROR = 'SET_SUBMIT_ERROR';

//STEP 1.
export const SAVE_SERVICE_ADDRESS_INFO = 'SAVE_SERVICE_ADDRESS_INFO';
export const SAVE_PLAN_INFO = 'SAVE_PLAN_INFO';
export const SAVE_DISCOUNTED_PRICE = 'SAVE_DISCOUNTED_PRICE';
export const SAVE_VAS_PLAN_INFO = 'SAVE_VAS_PLAN_INFO';
export const SAVE_WIRELESS_PLAN_INFO = 'SAVE_WIRELESS_PLAN_INFO';
export const SET_MOBILE_ONLY_FLOW = 'SET_MOBILE_ONLY_FLOW';
export const CHECK_SERVICEABILITY = 'CHECK_SERVICEABILITY';
export const CHECK_SERVICEABILITY_PENDING = 'CHECK_SERVICEABILITY_PENDING';
export const CHECK_SERVICEABILITY_REJECTED = 'CHECK_SERVICEABILITY_REJECTED';
export const CHECK_SERVICEABILITY_FULFILLED = 'CHECK_SERVICEABILITY_FULFILLED';
export const GET_SCHEDULE_APT = 'GET_SCHEDULE_APT';
export const GET_SCHEDULE_APT_FULFILLED = 'GET_SCHEDULE_APT_FULFILLED';
export const GET_SCHEDULE_APT_REJECTED = 'GET_SCHEDULE_APT_REJECTED';
export const GET_SCHEDULE_APT_PENDING = 'GET_SCHEDULE_APT_PENDING';
export const SERVICE_CHECK = 'SERVICE_CHECK';
export const SERVICE_CHECK_PENDING = 'SERVICE_CHECK_PENDING';
export const SERVICE_CHECK_REJECTED = 'SERVICE_CHECK_REJECTED';
export const SERVICE_CHECK_FULFILLED = 'SERVICE_CHECK_FULFILLED';
export const SMARTY_AUTOCOMPLETE = 'SMARTY_AUTOCOMPLETE';
export const UPDATE_IS_BUSINESS_ADDRESS = 'UPDATE_IS_BUSINESS_ADDRESS';
export const UPDATE_IS_REACH_ORDER = 'UPDATE_IS_REACH_ORDER';
export const SAVE_BUSINESS_INFO = 'SAVE_BUSINESS_INFO';
export const SAVE_IS_REPROVISIONING_INFO = 'SAVE_IS_REPROVISIONING_INFO';
export const SAVE_LANGUAGE_PREF = 'SAVE_LANGUAGE_PREF';
export const SET_EBB_CONFIRMATION_NUM = 'SET_EBB_CONFIRMATION_NUM';
export const EBB_DISCOUNT = 30;
export const MAX_EBB_LENGTH = 9;
export const SET_PREFERRED_LANGUAGE = 'SET_PREFERRED_LANGUAGE';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const SET_MOBILE_PLANS = 'SET_MOBILE_PLANS';
export const SET_ICCIDS = 'SET_ICCIDS';
export const SET_SIM_CARD_FLOW = 'SET_SIM_CARD_FLOW';
export const SET_SUCCESS_MESSAGE_TERM='SET_SUCCESS_MESSAGE_TERM';

export const SKIP_CREATE_ACCOUNT = 'SKIP_CREATE_ACCOUNT';
export const VAS_SKIP_CREATE_ACCOUNT = 'VAS_SKIP_CREATE_ACCOUNT';

//STEP 3
export const ACCOUNT_CHECK = 'ACCOUNT_CHECK';
export const ACCOUNT_CHECK_PENDING = 'ACCOUNT_CHECK_PENDING';
export const ACCOUNT_CHECK_REJECTED = 'ACCOUNT_CHECK_REJECTED';
export const ACCOUNT_CHECK_FULFILLED = 'ACCOUNT_CHECK_FULFILLED';

//STEP 4
export const SAVE_CHALLENGE_QUESTION_INFO = 'SAVE_CHALLENGE_QUESTION_INFO';

//STEP 6
export const SAVE_PAYMENT_INFO = 'SAVE_PAYMENT_INFO';
export const SET_ONE_TIME_PAYMENT_INFO = 'SET_ONE_TIME_PAYMENT_INFO';

//Prior to review order
export const UPDATE_CUST_TC = 'UPDATE_CUST_TC';
export const UPDATE_ERROR_MSG = 'UPDATE_ERROR_MSG';
export const UPDATE_CARD_ERROR = 'UPDATE_CARD_ERROR';

//RESET STATE
export const RESET_STATE = 'RESET_STATE';

//RESET HISTORY
export const RESET_HISTORY = 'RESET_HISTORY';

//ORDER SUBMISSION
export const INITIAL_ORDER_CREATE = 'INITIAL_ORDER_CREATE';
export const INITIAL_ORDER_CREATE_PENDING = 'INITIAL_ORDER_CREATE_PENDING';
export const INITIAL_ORDER_CREATE_REJECTED = 'INITIAL_ORDER_CREATE_REJECTED';
export const INITIAL_ORDER_CREATE_FULFILLED = 'INITIAL_ORDER_CREATE_FULFILLED';

export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_PENDING = 'GET_HISTORY_PENDING';
export const GET_HISTORY_FULFILLED = 'GET_HISTORY_FULFILLED';

export const VALIDATE_CARD = 'VALIDATE_CARD';
export const VALIDATE_CARD_PENDING = 'VALIDATE_CARD_PENDING';
export const VALIDATE_CARD_REJECTED = 'VALIDATE_CARD_REJECTED';
export const VALIDATE_CARD_FULFILLED = 'VALIDATE_CARD_FULFILLED';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_PENDING = 'UPDATE_ORDER_PENDING';
export const UPDATE_ORDER_REJECTED = 'UPDATE_ORDER_REJECTED';
export const UPDATE_ORDER_FULFILLED = 'UPDATE_ORDER_FULFILLED';

//reach order completed
export const REACH_ORDER_COMPLETED = 'REACH_ORDER_COMPLETED';

//update service_error in order for BIN errors
export const UPDATE_BIN_ERROR = 'UPDATE_BIN_ERROR';
export const UPDATE_BIN_ERROR_PENDING = 'UPDATE_BIN_ERROR_PENDING';
export const UPDATE_BIN_ERROR_REJECTED = 'UPDATE_BIN_ERROR_REJECTED';
export const UPDATE_BIN_ERROR_FULFILLED = 'UPDATE_BIN_ERROR_FULFILLED';

export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const SUBMIT_ORDER_PENDING = 'SUBMIT_ORDER_PENDING';
export const SUBMIT_ORDER_REJECTED = 'SUBMIT_ORDER_REJECTED';
export const SUBMIT_ORDER_FULFILLED = 'SUBMIT_ORDER_FULFILLED';

export const SAVE_PASSWORD = 'SAVE_PASSWORD';
export const SAVE_ADDRESS_IS_SAME = 'SAVE_ADDRESS_IS_SAME';

// Get Vas Products
export const GET_VAS_PRODUCTS = 'GET_VAS_PRODUCTS';
export const GET_VAS_PRODUCTS_PENDING = 'GET_VAS_PRODUCTS_PENDING';
export const GET_VAS_PRODUCTS_REJECTED = 'GET_VAS_PRODUCTS_REJECTED';
export const GET_VAS_PRODUCTS_FULFILLED = 'GET_VAS_PRODUCTS_FULFILLED';

// Reach Affiliate URL
export const REACH_AFFILIATE_URL = 'REACH_AFFILIATE_URL';
export const REACH_AFFILIATE_URL_PENDING = 'REACH_AFFILIATE_URL_PENDING';
export const REACH_AFFILIATE_URL_REJECTED = 'REACH_AFFILIATE_URL_REJECTED';
export const REACH_AFFILIATE_URL_FULFILLED = 'REACH_AFFILIATE_URL_FULFILLED';

// SDP Support
export const CREATE_SDP_TICKET = 'CREATE_SDP_TICKET';
export const CREATE_SDP_TICKET_FULFILLED = 'CREATE_SDP_TICKET_FULFILLED';
export const CREATE_SDP_TICKET_PENDING = 'CREATE_SDP_TICKET_PENDING';
export const CREATE_SDP_TICKET_REJECTED = 'CREATE_SDP_TICKET_REJECTED';

// check device coverage
export const CHECK_DEVICE_COMPATIBILITY = 'CHECK_DEVICE_COMPATIBILITY';
export const CHECK_DEVICE_COMPATIBILITY_FULFILLED =
  'CHECK_DEVICE_COMPATIBILITY_FULFILLED';
export const CHECK_DEVICE_COMPATIBILITY_PENDING =
  'CHECK_DEVICE_COMPATIBILITY_PENDING';
export const CHECK_DEVICE_COMPATIBILITY_REJECTED =
  'CHECK_DEVICE_COMPATIBILITY_REJECTED';

// Get retail stores
export const RETAIL_STORES = 'RETAIL_STORES';
export const RETAIL_STORES_PENDING = 'RETAIL_STORES_PENDING';
export const RETAIL_STORES_FULFILLED = 'RETAIL_STORES_FULFILLED';
export const RETAIL_STORES_REJECTED = 'RETAIL_STORES_REJECTED';
export const RETAIL_STORES_LOADED = 'RETAIL_STORES_LOADED';

// Save retail store address
export const RETAIL_STORE_ADDR = 'RETAIL_STORE_ADDR';

// Save optional Mailbox
export const SET_OPTIONAL_MAILBOX = 'SET_OPTIONAL_MAILBOX';
export const GET_SERVICE_PROPS_BY_CODE = 'GET_SERVICE_PROPS_BY_CODE';
export const GET_SERVICE_PROPS_BY_CODE_FULFILLED =
  'GET_SERVICE_PROPS_BY_CODE_FULFILLED';

// Save requested installation date
export const SET_REQ_INSTALL_DATE_TIME = 'SET_REQ_INSTALL_DATE_TIME';

//Get promo by source code
export const GET_PROMO_BY_SOURCE_CODE = 'GET_PROMO_BY_SOURCE_CODE';
export const GET_PROMO_BY_SOURCE_CODE_PENDING =
  'GET_PROMO_BY_SOURCE_CODE_PENDING';
export const GET_PROMO_BY_SOURCE_CODE_REJECTED =
  'GET_PROMO_BY_SOURCE_CODE_REJECTED';
export const GET_PROMO_BY_SOURCE_CODE_FULFILLED =
  'GET_PROMO_BY_SOURCE_CODE_FULFILLED';
export const SET_NEW_PROMO_CODE = 'SET_NEW_PROMO_CODE';
export const SET_CART_PROMO_CODE = 'SET_CART_PROMO_CODE';

// Bundles
export const GET_BUNDLES = 'GET_BUNDLES';
export const GET_BUNDLES_PENDING = 'GET_BUNDLES_PENDING';
export const GET_BUNDLES_REJECTED = 'GET_BUNDLES_REJECTED';
export const GET_BUNDLES_FULFILLED = 'GET_BUNDLES_FULFILLED';
export const SET_BUNDLES_CART = 'SET_BUNDLES_CART';

// Check promo code tracker
export const CHECK_PROMO_CODE_TRACKER = 'CHECK_PROMO_CODE_TRACKER';
export const CHECK_PROMO_CODE_TRACKER_PENDING =
  'CHECK_PROMO_CODE_TRACKER_PENDING';
export const CHECK_PROMO_CODE_TRACKER_REJECTED =
  'CHECK_PROMO_CODE_TRACKER_REJECTED';
export const CHECK_PROMO_CODE_TRACKER_FULFILLED =
  'CHECK_PROMO_CODE_TRACKER_FULFILLED';

// Promo code Counter
export const PROMO_CODE_COUNTER = 'PROMO_CODE_COUNTER';

// validate iccids mobile flow
export const VALIDATE_ICCIDS = 'VALIDATE_ICCIDS';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';

//Apply promo code
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const APPLY_PROMO_CODE_PENDING = 'APPLY_PROMO_CODE_PENDING';
export const APPLY_PROMO_CODE_REJECTED = 'APPLY_PROMO_CODE_REJECTED';
export const APPLY_PROMO_CODE_FULFILLED = 'APPLY_PROMO_CODE_FULFILLED';

//Clear promo states
export const CLEAR_APPLY_PROMO = 'CLEAR_APPLY_PROMO';

//Validate Address
export const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS';

// Term info confirmation
export const SET_TERM_INFO_CONFIRM = 'SET_TERM_INFO_CONFIRM';

//Set prequal error count
export const PREQUAL_ERROR_COUNT = 'PREQUAL_ERROR_COUNT';

// set orderAccessToken
export const SET_ORDER_ACCESS_TOKEN = 'SET_ORDER_ACCESS_TOKEN';
