import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'
import { routesConfig } from '../util/util'

export const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route {...rest}
      render={
        props => authenticated === true ?
          <Component {...props} />
          :
          <Redirect to={routesConfig.affiliateLogin}
          />
      }
    />
  )
}
