import React from 'react';
import Modal from 'react-bootstrap/Modal';

export const AccountAlreadyExistModal = (props) => {
    return (
      <Modal onHide={props.handleModalClose} show={true} className="modal" id="callSupport" backdrop="static" tabIndex="-1" role="dialog">
        <Modal.Header closeButton>
          <Modal.Title>Account Already Exists</Modal.Title>
        </Modal.Header>
        <div className="modal-body">EarthLink service was found on an Existing Customer account with this Email Address.  Please validate if this is an Existing Customer and transfer to Support, or try a different email.</div>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={props.handleModalClose}>End session</button>
          <button type="button" className="btn btn-secondary" onClick={props.handleEditEmail}>Try another email</button>
        </Modal.Footer>
      </Modal>
    )
  }