export const SecQueOptions = [
    'What was the make of your first car?',
    'What is the name of your first pet?',
    'What is your favorite movie?',
    'What is your favorite vacation spot?',
    'What is your maternal grandfather\'s first name?',
    'What city does your nearest sibling live in?',
    // 'What is your paternal grandfather\'s/grandmother\'s first name?',
    // 'Where did you meet your spouse for the first time?',
    // 'In what city or town did your mother and father meet?',
    // 'In what city did you meet your spouse/significant other?',
    // 'What is the first name of your oldest nephew/niece?',
    // 'What is your oldest sibling\'s middle name?',
    // 'What was the nickname of your grandfather/grandmother?',
    // 'In what city was your mother/father born? (city name only)',
    // 'What is your father\'s/mother\'s middle name?',
    // 'What is your favorite food?',
    // 'Who is your favorite author?',
    // 'What is/was your favorite restaurant?',
    // 'What is the name of your favorite childhood friend?',
    // 'What is your favorite dessert?',
    // 'What was your favorite summer job?',
    // 'What was the name of your favorite teacher in school?',
    // 'In what city were you living at age 16?',
    // 'What was the last name of your third grade teacher?',
    // 'What street did your best friend in school live on?',
    // 'What was the name of your first girlfriend/boyfriend?',
    // 'What was the name of the best man/maid of honor at your wedding?',
    // 'What was your first job title?',
    // 'What was the first live concert you attended?',
    // 'What was the first name of your first manager?',
    // 'What street did you live on in third grade?',
    // 'In what city did you honeymoon? (city name only)',
    // 'What was the name of your first stuffed animal?',
]
