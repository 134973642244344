import React from 'react';
import { OrderStepperContainer } from '../../containers/order'

class Order extends React.Component {

  render() {
    return (
      <div className="aff-main-content content">
        <div className="container-fluid">
          <OrderStepperContainer {...this.props} />
        </div>
      </div>
    )
  }
}

export default Order;
