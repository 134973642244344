import { useCallback } from 'react';

export default function useCXOneChat() {
  const insertChatScript = useCallback((opts) => {
    const { isAuth = false, } = opts || {};

    const snippet = `
    (function(n,u){
      window.BrandEmbassy=n,
      window[n]=window[n]||function(){(window[n].q=window[n].q||[]).push(arguments)},window[n].u=u,
      e=document.createElement("script"),e.async=1,e.src=u+"?"+Math.round(Date.now()/1e3/3600),
      document.head.appendChild(e)
      })('brandembassy','https://livechat-static-de-na1.niceincontact.com/4/chat.js');
      brandembassy('init', 2704, 'chat_23c6357b-3aba-4f05-9ef7-11bf6bede82e');
      brandembassy('enableChatAlwaysOnline')
      ` ;

    const script = document.createElement('script');

    script.dataset.auth = isAuth;
    script.innerHTML = snippet;
    script.type = 'text/javascript';

    document.head.appendChild(script);

    let retryCount = 0;
    const maxRetries = 20;
    const timer = setInterval(() => {
      retryCount += 1;
      if (window.brandembassy) {
        clearInterval(timer);
      } else if (retryCount >= maxRetries) {
        clearTimeout(timer);
      }
    }, 500);
  }, []);

  /**
   * Check if cxone chat is ready.
   */
  const isCXOneChatReady = () => {
    return typeof window?.brandembassy === 'function';
  };

  return {
    insertChatScript,
    isCXOneChatReady,
  };
}
