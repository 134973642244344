import React from 'react';
import ScrollToTop from 'react-scroll-up';

import { NewFooter } from './Footers';

const FooterPage = () => {
  return (
    <div style={{ width: '100%', position: 'absolute', bottom: '0' }}>
      <NewFooter />
      <ScrollToTop
        className="d-flex align-items-center"
        showUnder={0}
        duration={1000}
        style={{ bottom: '22px', right: '22px' }}
      >
        <i className="fa fa-angle-up fa-stack fa-rounded btn-default btn-hover-nobg"></i>
      </ScrollToTop>
    </div>
  );
};

export default FooterPage;
