export const ValidationMessages = {
    answersMatch: 'All the answers should be different',
    businessRequired: 'Please enter a value greater than 0',
    duplicateCCN: 'Duplicate CCN. Please enter other card',
    invalidCCN: 'Card is Invalid',
    invalidCVV: 'Invalid cvc',
    invalidEmail: 'Please enter a valid Email Address',
    invalidExpDate: 'Invalid expiration date',
    invalidPhoneNumber: 'Please enter a valid phone number',
    invalidUserid: 'User ID should be greater than 3',
    min10Length: 'Minimum 10 digits are required',
    min13Length: 'Minimum 13 digits are required',
    min2Length: 'Minimium 2 characters are required',
    min3Length: 'Minimum 3 digits are required',
    mobRequired: 'Field Required',
    noPrepaids: 'Prepaid cards are not allowed',
    notAcceptedCard: 'Invalid Card. EarthLink accepts the following: Visa, MasterCard, American Express and Discover.',
    notOnlyNumerics: 'Numerics alone are not allowed',
    notValidCard: 'Invalid card details',
    onlyAlphaNumeric: 'Only letters and digits allowed',
    onlyDigitsAllowed: 'Only digits are allowed',
    passwordInvalid: 'Incorrect Password format. Must be 8-32 characters and contain 3 of the following: uppercase letter, lowercase letter, a number or a special character.',
    passwordnotMatching: 'Passwords do not match',
    required: 'This field is required',
    validCharsOnly: 'Please enter letters only.',
    shouldNotBeSame:'First name and last name should not be same.',
    iccidMinLen: 'ICCID must be 19 digits',
    actCodeMinLen: 'Activation Code must be 11 characters',
    zipCodeMinLen: 'ZIP code must be 5 digits',
    tollFreeNumber : 'Toll-Free numbers are not accepted',
};
